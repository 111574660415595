import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Box, Button, Grid, Input, InputAdornment, OutlinedInput, Skeleton, TextField, Typography } from '@mui/material';
/* import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'; */
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { NumericFormat } from 'react-number-format';
import { NumberFormatCustom1 } from '../../core/FormattedInputs';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { current_user, g_language, getUrl } from '../../core/UrlService';
import { useTranslation } from 'react-i18next';
import { bg_color, text_color } from '../../themes/themeTelegram';
interface ChipData {
    key: number;
    label: number;
}

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

interface State {
    numberformat: string;
}

export default function PayFirst(props: any) {

    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

    }, [])


    const { t, i18n } = useTranslation();

    const location = useLocation();

    const { state } = useLocation();
    const { UserId } = state || {};
    const [chipData, setChipData] = React.useState<readonly ChipData[]>([
        { key: 0, label: 30 },
        { key: 1, label: 45 }


    ]);
    const [data, setData] = React.useState(null);
    const [description, setDescription] = React.useState("");
    const [sumToPay, setSumToPay] = React.useState();
    const [values, setValues] = React.useState<State>({
        numberformat: "0"
    });

    const handleDelete = (chipToDelete: ChipData) => () => {
        setChipData((chips) => chips.filter((chip) => chip.key == chipToDelete.key));
    };

    const onChipClick = (key: any) => {
        setSumToPay(key)

        setValues({
            ...values,
            "numberformat": key
        });
        /*     if (props.tarif.length > 0)
                setDescription("на " + (key / (props.tarif.length * 3)) + " дней по текущему тарифу") */
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({

            "numberformat": event.target.value
        });


        if (event.target.value != null) {

            var a = event.target.value.toString().replace(' WP', '');
            //setSumToPay(event.target.value as any)
            /*    if (props.tarif.length > 0)
                   setDescription("на " + Math.round(((Number(a)) / (props.tarif.length * 3))) + " дней по текущему тарифу")
    */
            if (Number(a) < 90) {
                setDescription("мин. сумма 90 WP")
            }
            else {
                if (Number(a) > 100000) {
                    setDescription("макс. сумма " + (45 * 3) + " WP")
                }
                else {
                    if (a == "")
                        setDescription("мин. сумма 90 WP")
                }
            }
        }
    };



    var getConn = () => {

        var a = location.state;
        fetch(getUrl("/api/connections", props.tg) + "id=" + a)
            //fetch("http://localhost:5010" + "/api/connections?id=" + a)
            .then(res => res.json())
            .then(
                (result) => {

                    /*  var a = result */

                    setData(result);
                    /*   setIsConnLoading(false) */
                })
    }
    const navigate = useNavigate();
    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

    }, [])

    React.useEffect(() => {

        props.tg.MainButton.setText(t("main_button.go_to_top_up")) //  метод для задания текста
        props.tg.MainButton.show() // показать кнопку 
        /* props.tg.MainButton.disable() // показать кнопку  */
       
        props.tg.MainButton.onClick(() => {
            navigate("/pay_detail_first", { state: { amount: a } })

        })



        var a = 3 * 30
        setValues({
            ...values,
            "numberformat": a as any
        });

        setDescription(t("first_to_top_up.desc"))
        getConn()

    }, [])

    var onBackClick = () => {
        navigate("/")
    }

    return (

        <Box sx={{ mt: 2 }}>
            <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                <b>{t("first_to_top_up.title")}</b>
            </Typography>
            {props.tarif != null && (props.tarif).IsEmpty == true

                ? <div></div>
                : <Paper
                    sx={{
                        background:  bg_color,
                        display: 'flex',
                        boxShadow: 'none',
                        flexDirection: 'column',
                        /*   justifyContent: 'center',
                          flexWrap: 'wrap', */
                        borderRadius: '12px',
                        listStyle: 'none',
                        p: 2,
                        mt: 2,


                    }}
                    component="ul"
                >
                    <Typography variant="caption" sx={{ color: '#BBBBBB', fontSize: ' 0.675rem', mb: 1 }} >
                        {t("first_to_top_up.title_1")}
                    </Typography>

                    {/* <Grid container spacing={2} mb={1}>

                        <Grid item md={3} xs={6} > */}


                    <div style={{ display: 'flex' }} className="numeric_custom_div">
                        <NumericFormat
                            onChange={handleChange}
                            value={values.numberformat}

                            className="numeric_custom"
                            style={{ width: '100%', display: 'flex', color: text_color }}

                            suffix=" WP"
                        />
                    </div>
                    {/*  </Grid> */}
                    {/* <Grid item md={2} xs={6}  >
                            <Button   variant="contained" aria-label="add to shopping cart" disabled={setEnabled()} sx={{ color: 'white', borderRadius: 0, backgroundColor: '#ffc107', p: 1, width: '100%' }}
                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onPayClick}>
                                Пополнить
                                <CreditCardIcon sx={{ ml: 0.5 }} />
                            </Button >
                        </Grid> */}
                    {/*    </Grid> */}
                    <Typography sx={{ mb: 1 }} variant="caption" style={{ color: '#bbbbbb' }}>{description}</Typography>


                    {/*   {
                       (data as any) != null &&  (data as any).Connections.length == 0
                            ? <Skeleton height={40} />

                            :  */}
                    <div style={{ display: 'flex', /* justifyContent: 'center' */ }}>
                        {chipData.map((data) => {
                            return (

                                <ListItem key={data.key}>

                                    <Chip style={{ background: "#ffc107", color: 'white', width: '80px' }} variant="filled"
                                        clickable={true}
                                        onClick={() => onChipClick(data.label * 3)}
                                        label={(data.label * 3) + " WP "}

                                    />
                                </ListItem>
                            );
                        })}
                    </div>
                    {/*    }  */}

                </Paper>
            }
        </Box>
    )
};
