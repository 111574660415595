import { Avatar, Box, Chip, FormControlLabel, FormGroup, Grid, Paper, Typography } from "@mui/material"
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { g_language } from "../../core/UrlService";
import { bg_color, text_color } from "../../themes/themeTelegram";
export default function RulePage_3(props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    React.useEffect(() => {
        props.tg.MainButton.setText(t("main_button.next"))  //  метод для задания текста
        props.tg.MainButton.show() // показать кнопку 
       
        props.tg.MainButton.onClick(() => {
            navigate("/rule_page_4")
        })
    }, [])

    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

    }, [])
    var onHelpClick = () => {
        props.tg.openTelegramLink("https://t.me/whypnbot")
    }

    return (
        <Box sx={{ mt: 2 }}>
            <Box >

                <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                    <b>{t("rule_page_3.main_title")}</b>
                </Typography>
                <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                    {t("rule_page_3.title_1")}:
                </Typography>

                {/*     <ul>
                    <li> <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                        бесплатные
                    </Typography></li>
                    <li> <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                        с ежедневной оплатой
                    </Typography></li>
                    <li> <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                        с ежемесячной оплатой
                    </Typography></li>
                </ul>
 */}

                <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                    - {t("rule_page_3.title_2_1")}
                </Typography>
                <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                    - {t("rule_page_3.title_2_2")}
                </Typography>
                <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                    - {t("rule_page_3.title_2_3")}
                </Typography>


                <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                {t("rule_page_3.title_3")}
                </Typography>

                <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                {t("rule_page_3.title_4")}&nbsp;
                    <a onClick={onHelpClick}><u>@whypnbot</u></a>
                    {t("rule_page_3.title_4_1")}
                </Typography>

            </Box>
            <Box sx={{ mt: 2, mb: 2, textAlign: 'center', position: 'fixed', bottom: 5, left: 5, right: 5 }} >
            <Chip style={{ background: bg_color}} label={<Typography variant="caption" sx={{ color: text_color, width: '100%' }}  onClick={() => { navigate("/set_up") }}>
                {t("rule_page_3.chip")}
                </Typography>} />
            </Box>

            {/*    <Box sx={{ mt: 2, mb: 2 }} >
                <Typography sx={{ textAlign: 'center', position: 'fixed', bottom: 20, left: 5, right: 5, color: text_color, width: '100%' }} onClick={() => { navigate("/set_up") }}>
                    <u>Пропустить обучение</u>
                </Typography>
            </Box> */}
        </Box>


    )
}