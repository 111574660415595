import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid, IconButton, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function Help(props: any) {

    const [data, setData] = React.useState([]);
    const navigate = useNavigate();
    const params = useParams();

    var onCreateCodeClick = () => {
        /*    fetch("http://localhost:5010" + '/api/update?id=' + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   1297445062        ) + '&name=' + "test")
               .then(res =>
                   navigate("history")
               ); */
        window.open('https://donate.qiwi.com/payin/WhyPN', '_blank')
    }

    var onBackClick = () => {
        navigate("/")
    }
    var onAppleClick = () => {
        window.open("https://telegra.ph/WhyPN-dlya-iPhone-i-iPad-08-31", "_blank")
    }

    var onOutlineClick = () => {
        window.open("https://telegra.ph/WhyPN-ShadowSocks-08-16", "_blank")
    }

    var onAndroidClick = () => {
        window.open("https://telegra.ph/WhyPN--Android-08-31", "_blank")
    }
    return (
        <Box /* style={{ background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white' }} */>
            <Box mb={2} mt={1} sx={{
                /*    background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white', */
                display: 'flex',
                color: props.tg.themeParams.text_color ?? '#000000',
                flexDirection: 'row',
                alignItems: 'center',

            }}>
                <IconButton aria-label="delete" sx={{ p: 0, pr: 1 }} style={{ color: props.tg.themeParams.text_color ?? '##000000' }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography color={props.tg.themeParams.text_color ?? '##000000'} variant="h6" gutterBottom sx={{ m: 0 }}> Помощь</Typography>
            </Box>
            <Box sx={{ p: 2, background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white', borderRadius: '12px' }}>
                <Typography variant="caption" sx={{ mb: 3, color: '#BBBBBB' }}>КАК ПОДКЛЮЧИТЬСЯ?</Typography>
     {/*            <Box
                    onClick={onAndroidClick}
                    sx={{
                        p: 1,
                        mb: 2,
                        mt: 2,
              borderRadius: '12px',
                        border: '1px solid #edeef0',
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: props.tg.themeParams.text_color ?? '##000000'
                    }}>
                    <Link href="https://telegra.ph/WhyPN--Android-08-31" target="_blank" underline="hover" style={{ color: props.tg.themeParams.text_color ?? '##000000' }}>
                        Инструкции для Android
                    </Link>
                    <OpenInNewIcon></OpenInNewIcon>
                </Box> */}
          {/*       <Box onClick={onAppleClick} sx={{
                    p: 1,
                    mb: 2,
                    border: '1px solid #edeef0',
              borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'space-between', color: props.tg.themeParams.text_color ?? '##000000'

                }}>
                    <Link href="https://telegra.ph/WhyPN-dlya-iPhone-i-iPad-08-31" target="_blank" underline="hover" style={{ color: props.tg.themeParams.text_color ?? '##000000' }}>
                        Инструкции для iPhone
                    </Link>
                    
                    <OpenInNewIcon></OpenInNewIcon>
                </Box> */}

                <Box onClick={onOutlineClick} sx={{
                    p: 1,

                    border: '1px solid #edeef0',
            /*     background: props.tg.themeParams.bg_color, */
/*                 boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
 */                borderRadius: '12px',
                    display: 'flex',
                    justifyContent: 'space-between', color: props.tg.themeParams.text_color ?? '##000000'

                }}>
                    <Link href="https://telegra.ph/WhyPN-ShadowSocks-08-16" target="_blank" underline="hover" style={{ color: props.tg.themeParams.text_color ?? '##000000' }}>
                        Инструкции для ShadowSocks
                    </Link>
                    
                    <OpenInNewIcon></OpenInNewIcon>
                </Box>
            </Box>
        </Box >
    );
} 