import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, Avatar, Box, Button, ButtonGroup, CircularProgress, Divider, Grid, IconButton, Link, Skeleton, Stack, styled } from '@mui/material';
import Moment from 'moment';
import "./styles.css"
import AddIcon from '@mui/icons-material/Add';
import { redirect, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import ComputerIcon from '@mui/icons-material/Computer';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DownloadIcon from '@mui/icons-material/Download';
import { g_language, getUrl } from '../../core/UrlService';
import { Edit } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import CopyToClipboard from 'react-copy-to-clipboard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import NewUserPay from '../New/NewUserPay';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';


import { current_user } from '../../core/UrlService';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import TmConnection from './Connections/TmConnection';
import PublicIcon from '@mui/icons-material/Public';
import OutlineConnection from './Connections/OutlineConnection';
import WgConnection from './Connections/WgConnection';
import NoneConnection from './Connections/NoneConnection';
import PcConnection from './Connections/PcConnection';
import Instructions from '../Instructions/Instructions';

import { useTranslation } from 'react-i18next';
import { bg_color, button_color, text_color } from '../../themes/themeTelegram';
import AlertDialog from './AlertDialog';
import AlertDialog_1 from './AlertDialog_1';
import UpdateLimitDialog from './UpdateLimitDialog';
import { getHtmlToRedirect } from './test';
import DeleteDialog from './DeleteDialog';



function pr(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" style={{ background: 'rgb(239, 239, 239)' }}  {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" /* color="text.secondary" */>{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


export default function CodeList(props: any) {

    const navigate = useNavigate();
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [isLoading, setIsLoading] = React.useState(false);

    const [limit, setLimit] = React.useState(-1);

    React.useEffect(() => {

        if (props.data.length > 0) {
            fetch(getUrl("/api/limit", props.tg) + "serverId=" + props.data[0].ServerId + "&internalId=" + props.data[0].InternalId.String?.toString())
                //fetch("http://localhost:5010" + "/api/available")
                .then(res => res.text())
                .then(
                    (result: any) => {

                        setLimit(Number(result))
                        //setFromCountries(result);
                    },
                    (error) => {
                        //setIsLoaded(true);
                        //setError(error);
                    }
                )
        }
    }, [])


    const handleChange =

        (panel: string, serverId: any, internalId: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {

            setLimit(-1)
            fetch(getUrl("/api/limit", props.tg) + "serverId=" + serverId + "&internalId=" + internalId.String?.toString())
                //fetch("http://localhost:5010" + "/api/available")
                .then(res => res.text())
                .then(
                    (result: any) => {

                        setLimit(Number(result))
                        /*  setFromCountries(result); */
                    },
                    (error) => {
                        //setIsLoaded(true);
                        //setError(error);
                        setLimit(-1)
                    }
                )
            setExpanded(isExpanded ? panel : false);
        };

    var getStatusSymbol = (enabled: Boolean, confId: any, isLink: Boolean, internalId: any, code: string, ping = false, type: number) => {


        if (confId != 0) {
            return enabled ? <ComputerIcon style={{ color: 'green', paddingRight: 5 }}></ComputerIcon> : <ComputerIcon style={{ color: 'red', paddingRight: 5 }}></ComputerIcon>
        }



        if (isLink) {


            if (type == 41) {
                return <PublicIcon style={{ color: enabled ? 'green' : "red", paddingRight: 5 }}></PublicIcon>
            }
            else if (type == 42) {
                return <ComputerIcon style={{ color: enabled ? 'green' : "red", paddingRight: 5 }}></ComputerIcon>
            }
            else if (ping || internalId == "") {
                return <LinkIcon style={{ color: 'black', paddingRight: 5 }}></LinkIcon>

            }
            else {
                return enabled ? <LinkIcon style={{ color: 'green', paddingRight: 5 }}></LinkIcon> : <LinkIcon style={{ color: 'red', paddingRight: 5 }}></LinkIcon>
            }
        }

        if (code == "none") {
            return <QrCodeIcon style={{ color: 'black', paddingRight: 5 }}></QrCodeIcon>
        }

        return enabled ? <QrCodeIcon style={{ color: 'green', paddingRight: 5 }} ></QrCodeIcon> : <QrCodeIcon style={{ color: 'red', paddingRight: 5 }}></QrCodeIcon>
        // return enabled ? "🟢" : "🔴"

    }


    var getStatusName = (enabled: Boolean, id: number, name: string) => {

        if (name == "") {
            return t("connection") + " #" + id
        }
        else {
            return name
        }

        //  return enabled ? <QrCodeIcon style={{color:'green',  paddingRight:5}} ></QrCodeIcon> : <QrCodeIcon style={{color:'red', paddingRight:5}}></QrCodeIcon> 
        // return enabled ? "🟢" : "🔴"

    }

    var returnToNewCodeForm = () => {

        navigate("/new", { state: { info: props.info, conn: props.data } })

        /*     navigate("/new", { state: { id: item.Id, name: item.Name?.String == "" ? "empty" : item.Name?.String } })  */
        /* { state: { id: item.Id, name: item.Name?.String == "" ? "empty" : item.Name?.String } }) } */
    }

    var onDeleteClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {
        setCurrentConn(id)
        setOpen2(true)
    }






    const handleClientsDownload = async (response: any) => {


        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(response));
        element.setAttribute('download', `your_file_name.txt`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    const BootstrapButton = styled(Button)({
        borderBottom: '1px solid !important',
        borderRadius: '0px',
        padding: '0px',

        marginBottom: '5px',
        color: '#ffc107',//props.tg.themeParams.text_color ?? '#1E88E5',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: '0,85rem',
        backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
        borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',

        '&:hover': {
            backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
            borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
            borderColor: props.tg != undefined && props.tg.colorScheme == 'dark' ? '#323232' : 'white',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    });

    const [isOpen, setIsOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [state, setState] = React.useState<any>({
        value: '',
        copied: false,
    })
    var onCopyClick = (event: any) => {
        setState({ value: 'dddsd', copied: false })
        setAnchorEl(event.currentTarget);
        setIsOpen(true)
    }

    const [open1, setOpen1] = React.useState(false);

    const [currentConn, setCurrentConn] = React.useState(-2);
    const [currentTariff, setCurrentTariff] = React.useState(-2);
    const [isOutline, setIsOutlinen] = React.useState(false)

    const [isTm, setIsTm] = React.useState(false)

    const [isMulti, setIsMulti] = React.useState(false);

    const [isOk, setisOK] = React.useState(false);

    const [isLoadingButton, setIsLoadingButton] = React.useState(false);


    const [deleteLoading, setDeleteLoading] = React.useState(false);
    const { t, i18n } = useTranslation()
    const [text, setText] = React.useState( t("alert_refresh.text_1")/* `Выбранное подключение будет удалено.
    Вместо него будет создано новое подключение от WhyPN на базе ShadowSocks.` */);


    var onChangeProtocClick = (id: any, isMulti: any, user_id: any, isOutline: boolean, tariff: any) => {


        if (props.balance > 0) {
            setCurrentTariff(tariff)
            setCurrentConn(id)
            setOpen1(true)
        }
        else {
            navigate("/pay_refresh")
        }
    }

    var onChangeProtocClick_ = (id: any, isMulti: any, user_id: any, isOutline: boolean, tariff: any) => {


        if (props.balance > 0) {
            setCurrentTariff(tariff)
            setCurrentConn(id)
            setOpen1(true)
        }
        else {
            navigate("/pay_refresh")
        }
    }
    const [open2, setOpen2] = React.useState(false);


    var onDeleteOpenClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {


        setIsOutlinen(isOutline)
        setCurrentConn(id)
        setIsMulti(isMulti)
        setOpen2(true)
    }



    var onTmDeleteOpenClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {


        setIsOutlinen(false)
        setIsTm(true)
        setCurrentConn(id)
        setIsMulti(isMulti)
        setOpen2(true)
    }


    var onTmDeleteOpenClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {


        setIsOutlinen(false)
        setIsTm(true)
        setCurrentConn(id)
        setIsMulti(isMulti)
        setOpen2(true)
    }

    const [updateWindowsOpen, setOpdateWindowsOpen] = React.useState(false);
    const [updateSum, setUpdateSum] = React.useState(0);
    const [updateLimit, setUpdateLimit] = React.useState(0);


    const handleUpdateClose = () => {
        //setOpen1(false);
        //window.location.reload();
        setOpdateWindowsOpen(false)

    };



    var onUpdateLimitOpenClick = (sum: any, limit: any, id: any,) => {

        if (props.balance < sum) {
            navigate("/pay_detail", { state: { amount: sum, text: "Для сброса трафика недостаточно средств. Пожалуйста, пополните баланс на " + sum + " WP" } })
        }
        else {
            /*        setIsOutlinen(false)
                   setIsTm(true)
                   setCurrentConn(id)
                   setIsMulti(isMulti) */


            setCurrentConn(id)
            setUpdateSum(sum)
            setOpdateWindowsOpen(true)
            setUpdateLimit(limit)
        }
    }




    var onDeleteClickClose = () => {

        setOpen2(false)
    }

    var onDeleteOpenApprove = () => {


        if (isTm) {
            fetch(getUrl("/api/remove_outline_tm", props.tg) + "id=" + currentConn)
                /*  fetch("http://localhost:5010" + '/api/remove_old?id=' + user_id) */
                .then(res => {
                    /*  props.refresh() */
                    window.location.reload();
                }, error => {
                    window.location.reload();
                })
                ;
        }


        else if (isOutline) {
            fetch(getUrl("/api/remove_outline", props.tg) + "id=" + currentConn)
                /*  fetch("http://localhost:5010" + '/api/remove_old?id=' + user_id) */
                .then(res => {
                    /*  props.refresh() */
                    window.location.reload();
                }, error => {
                    window.location.reload();
                })
                ;
        }
        else if (!isMulti) {
            fetch(getUrl("/api/remove_old", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
                /*  fetch("http://localhost:5010" + '/api/remove_old?id=' + user_id) */
                .then(res => {
                    /* props.refresh() */
                    window.location.reload();
                }, error => {
                    window.location.reload();
                })
                ;

        }
        else {
            /*    fetch("http://localhost:5010" + '/api/remove?id=' + id) */
            fetch(getUrl("/api/remove", props.tg) + "id=" + currentConn)
                .then(res => {
                    /*   props.refresh() */
                    window.location.reload();
                }, error => {
                    window.location.reload();
                }
                );
        }

        setDeleteLoading(true)
        //setOpen2(false)
    }

  
    const handleClickOpen = () => {
        setIsLoadingButton(true)

        fetch(getUrl("/api/refresh", props.tg) + "conn_id=" + currentConn + "&user=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + "&tarrifId=" + currentTariff)

            .then(res => {
                //  props.refresh()

                if (res.status == 402) {
                    redirect("/pay")
                }
                setIsLoadingButton(false)
                setisOK(true)
                setText(t("alert_refresh.text_2"))
                // setOpen1(false);



            }, error => {
                window.location.reload();
            })
            ;




    };


    const handleClickOpen_1 = () => {
        setIsLoadingButton(true)

        fetch(getUrl("/api/refresh_free", props.tg) + "conn_id=" + currentConn + "&user=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + "&tarrifId=" + currentTariff)

            .then(res => {
                //  props.refresh()

                if (res.status == 402) {
                    redirect("/pay")
                }
                setIsLoadingButton(false)
                setisOK(true)
                setText(t("alert_refresh.text_2"))
                // setOpen1(false);



            }, error => {
                window.location.reload();
            })
            ;


    };

    const handleUpdateClickOpen = () => {
        setIsLoadingButton(true)

        fetch(getUrl("/api/update_limit", props.tg) + "id=" + currentConn + "&user=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + "&sum=" + updateSum)

            .then(res => {


                //  props.refresh()

                /*  if (res.status == 402) {
                     redirect("/pay")
                 }
                 setIsLoadingButton(false)
                 setisOK(true)
                 setText("Подключение успешно обновлено") */
                // setOpen1(false);


                window.location.reload();

            }, error => {

                window.location.reload();
            })
            ;


    };


    const onInstructionClick = (instruction) => {
        /*  setOpen1(false); */
        // window.location.reload();

        navigate("/instructions", { state: { instruction: instruction } })
    };

    const handleClose = () => {
        setOpen1(false);
        // window.location.reload();
    };

    var test = (ss: string) => {

        const winUrl = URL.createObjectURL(
            new Blob([getHtmlToRedirect(ss)], { type: "text/html" })
        );

        var windowSize = "width=" + window.innerWidth + ",height=" + window.innerHeight + ",scrollbars=no";

        window.open(getUrl("/api/openss", props.tg) + "id=" + ss)



        /*   window.open(
              getHtmlToRedirect(ss), '_blank', windowSize
          );
      */

        /* let newWindow = window.open();
        (newWindow as any).document.write( getHtmlToRedirect(ss),); */
        //window.open("https://whypn.com/testify02.html")
        /*  (child as any).document.write(getHtmlToRedirect(ss));
         (child as any).document.close(); */
        /*   var __html = require('./template.html');
          var template = { __html: __html }; */

        /*  return React.createElement("h1", {dangerouslySetInnerHTML: {__html: html}}) */

        /* const winHtml = `<!DOCTYPE html>
        <html>
            <head>
                <title>Window with Blob</title>
            </head>
            <body>
                <h1>Hello from the new window!</h1>
            </body>
        </html>`;
    
    const winUrl_ = URL.createObjectURL(
        new Blob([winHtml], { type: "text/html" })
    );
    
    
    const win = window.open(
       winUrl_,
        "win",
        `width=800,height=400,screenX=200,screenY=200`
    ); */
    }


    const onChangeOpenApprove = () => {
        setOpen1(false);
        window.location.reload();
    };

    const onChangeOpenApprove_1 = () => {
        setOpen1(false);
        window.location.reload();
    };
    const [progress, setProgress] = React.useState(70);

   
    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])

    return (

        <Box>
            <DeleteDialog isDeleteLoading={deleteLoading} dialog_open={open2} handleClickOpen={onDeleteOpenClick} onDeleteClickClose={onDeleteClickClose} handleApprove={onDeleteOpenApprove} isOk={isOk} isLoading={isLoadingButton} tg={props.tg}></DeleteDialog>
            <AlertDialog dialog_open={open1} handleClickOpen={handleClickOpen} handleClose={handleClose} handleApprove={onChangeOpenApprove} isOk={isOk} text={text} isLoading={isLoadingButton} tg={props.tg}></AlertDialog>
            <AlertDialog_1 dialog_open={open1} handleClickOpen={handleClickOpen_1} handleClose={handleClose} handleApprove={onChangeOpenApprove_1} isOk={isOk} text={text} isLoading={isLoadingButton} tg={props.tg}></AlertDialog_1>

            <UpdateLimitDialog
                isDeleteLoading={deleteLoading}
                dialog_open={updateWindowsOpen}
                handleClickOpen={handleClickOpen}
                handleClose={handleUpdateClose}
                handleApprove={handleUpdateClickOpen} isOk={isOk}
                isLoading={isLoadingButton} tg={props.tg}
                sum={updateSum}
                limit={updateLimit}
            >
            </UpdateLimitDialog>


            <style
                dangerouslySetInnerHTML={{
                    __html: `
    .test_1:after {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(140.9deg, `+ button_color + ` -14.02%, rgba(144, 202, 249, 0) 70.5%);
    border-radius: 50%;
    top: -160px;
    right: -130px;
}

.test_1::before {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(210.04deg, `+ button_color + ` -50.94%, rgba(144, 202, 249, 0) 83.49%);
    border-radius: 50%;
    top: -30px;
    right: -180px;
    cursor: pointer;
}

.test_1 {
    /*     background-color: rgb(255, 255, 255) !important;
    color: rgb(54, 65, 82) !important; */
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    box-shadow: none !important;
    background-image: none !important;
    border-radius: 12px !important;
    border: none rgba(144, 202, 249, 0.145) !important;
    overflow: hidden !important;
    position: relative !important;
}

    `
                }}
            ></style>

            {props.data != null
                ? props.data.IsEmpty == true
                    ? <div><NewUserPay tg={props.tg}></NewUserPay></div>

                    : <Box sx={{ p: 2 }} style={{ background: bg_color, borderRadius: '12px', boxShadow: 'none' }} className='test_1'
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 1
                        }}>

                            
                            <Typography style={{ color: text_color}}>{t("connections")}</Typography>
                            <IconButton
                                style={{ zIndex: 200 }}
                                onClick={returnToNewCodeForm}

                                sx={{
                                    /*        ...theme.typography.commonAvatar,
                                           ...theme.typography.largeAvatar, */

                                    borderRadius: '10px', textTransform: 'none', boxShadow: 'none',
                                    width: 30, height: 30
                                }}
                            >
                                <AddIcon fontSize="inherit" />
                            </IconButton>
                        </Box>
                        < Divider style={{ color: '#edeef0' }}></Divider>


                        {

                            props.data == null
                                ? <Box sx={{ mt: 1 }}>
                                    <Typography style={{ color: text_color, textAlign: 'center' }}>You don't have a QR code yet :(</Typography>

                                </Box>
                                :

                                props.data?.map((item: any, i: any) => {
                                    var a = Moment(item.Date)
                                    const formatDate = Moment(item.Date).utcOffset('GMT-00:00').format('DD.MM.YYYY HH:mm')

                                    // Return the element. Also pass key     
                                    return (
                                        <div>
                                            <Accordion onClick={() => setLimit(-1)} sx={{ p: 0 }} 
                                            expanded={expanded === 'panel' + (i + 1)}/*  defaultExpanded={i === 0 ? true : false}   */ 
                                            onChange={handleChange('panel' + (i + 1), item.ServerId, item.InternalId)} 
                                            style={{ background: bg_color, position: 'unset', boxShadow: 'none', padding: '0px' }}
                                                className="accordion_test"
                                            >
                                                <AccordionSummary
                                                    className="accordion_test"
                                                    expandIcon={<ExpandMoreIcon style={{ color: text_color}} />}
                                                    aria-controls="panel2bh-content"
                                                    id="panel2bh-header"
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                                        <Typography sx={{ width: '33%', flexShrink: 0 }} style={{ width: '100%', display: 'flex', alignItems: 'center' }}>

                                                            {getStatusSymbol(item.IsEnabled, item.ConfId?.Int64, [7, 40, 41, 42, 4040].includes(item.ServerTypeId), item.InternalId.String?.toString(), item.Code, item.Code == '-3', item.ServerTypeId)}

                                                            {/*   <IconButton style={{ color: props.tg.themeParams.text_color ?? 'black' }} onClick={() => { navigate("/edit", { state: { id: item.Id, name: getStatusName(item.IsEnabled, item.Id, item.Name?.String) } }) }}>
                                                                    <Edit />
                                                                </IconButton> */}
                                                            <div>
                                                                <Typography>{getStatusName(item.IsEnabled, item.Id, item.Name?.String)}</Typography>
                                                                <Typography variant="caption"><b>{item.Id}</b> {t("by")} {formatDate} UTC</Typography>
                                                            </div>

                                                        </Typography>

                                                    </div>
                                                </AccordionSummary>








                                                <AccordionDetails>

                                                    {/*  {item.ServerTypeId != 7 && item.ServerTypeId != 40 && item.ServerTypeId != 41
                                                        ? <Alert sx={{ mb: 2 }} severity="error"><Typography>Подключение может быть заблокировано вашим оператором связи (провайдером). Для стабильной работы обновите подключение</Typography> <Link href="https://telegra.ph/WhyPN-dlya-Beeline-08-05" target="_blank" underline="hover" style={{ color: '' }}>Инструкции</Link></Alert>
                                                        : <div></div>} */}

                                                    {/* {item.ServerTypeId != 41 && item.ServerTypeId != 7 && item.ServerTypeId != 40  && item.ServerTypeId != 3 &&  item.ServerTypeId != 4 ?
                                                        <Grid container spacing={2} mb={1.5}>
                                                            <Grid item xs={12}>
                                                                        <Grid
                                                                    container
                                                                    direction="row"
                                                                    justifyContent="start"
                                                                    alignItems="center"


                                                                >


                                                                    <IconButton
                                                                         
                                                                        size="small"

                                                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                                        sx={{ mr: 1, textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                                        onClick={() => { navigate("/edit", { state: { id: item.Id, name: item.Name?.String == "" ? "empty" : item.Name?.String } }) }}
                                                                    >
                                                                        <EditIcon />
                                                                    </IconButton >


                                                                    <IconButton
                                                                         
                                                                        size="small"

                                                                        style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                                        sx={{ mr: 1, textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                                        onClick={() => { props.setLoadingState(); onChangeProtocClick(item.Id, item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, [7, 40, 41,42].includes(item.ServerTypeId)) }}
                                                                    >
                                                                        <RefreshIcon />
                                                                    </IconButton >

                                                                    {(props.data.length > 1) ?
                                                                        <IconButton
                                                                             
                                                                            size="small"

                                                                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                                            sx={{ textTransform: 'none', background: '#ffc107', color: 'white', borderRadius: 0, backgroundColor: '#ffc107' }}
                                                                            onClick={() => { props.setLoadingState(); onDeleteOpenClick(item.Id, item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, [7, 40, 41,42].includes(item.ServerTypeId)) }}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                        : <div></div>
                                                                    }

                                                                </Grid>


                                                            </Grid>

                                                        </Grid>

                                                        : <></>
                                                    } */}



                                                    {

                                                        item.IsDesc
                                                            ?


                                                            <div>

                                                                {/*       <Grid container spacing={2} mb={1.5}>
                                                                    <Grid item xs={5}>
                                                                        <Typography>ID</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={7}>
                                                                        <Typography textAlign={'right'}>{formatDate} MSK</Typography>
                                                                    </Grid>
                                                                </Grid>
 */}


                                                                {/*  <Grid container spacing={2} mb={1.5}>
                                                                    <Grid item xs={5}>
                                                                        <Typography>Дата создания</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={7}>
                                                                        <Typography textAlign={'right'}>{item.Id}</Typography>
                                                                    </Grid>
                                                                </Grid> */}

                                                                <Grid container spacing={2} mb={1.5}>
                                                                    <Grid item xs={5}>
                                                                        <Typography>{t("region")}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={7}>
                                                                        <Typography textAlign={'right'}><i className={"em em-flag-" + item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> {item?.City?.String}</Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid container spacing={2} mb={1.5}>
                                                                    <Grid item xs={5}>
                                                                        <Typography>{t("server")}</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={7} >
                                                                        <Typography textAlign={'right'}>{item.Domain}</Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid container spacing={2} mb={1.5}>
                                                                    <Grid item xs={5}>
                                                                        <Typography>Статус1</Typography>
                                                                    </Grid>
                                                                    <Grid item xs={7}>
                                                                        <Typography textAlign={'right'}>{item.IsEnabled ? "🟢 OK" : "🔴 " + t("disable")}</Typography>
                                                                    </Grid>
                                                                </Grid>



                                                                {/*    <Grid item xs={12}> */}
                                                                {/*   <pr  value={progress} /> */}
                                                                {/* </Grid> */}
                                                                {/*   <Grid item xs={7}>
                                                                        <Typography textAlign={'right'}>{item.IsEnabled ? "🟢 OK" : "🔴 Заблокировано"}</Typography>
                                                                    </Grid> */}


                                                                <Box
                                                                    display="flex"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    sx={{
                                                                        borderRadius: '12px'
                                                                    }}

                                                                >

                                                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', background: bg_color, color: text_color}} onClick={() => { handleClientsDownload(item.File) }}>
                                                                        <DownloadIcon style={{ fontSize: 50 }}></DownloadIcon>
                                                                        <Typography>Файл конфигурации</Typography>

                                                                    </div>
                                                                    {/* <Button
                                                                            sx={{ mb: 1, mt: 2 }} style={{ width: '100%', background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'none', color: props.tg.themeParams.text_color ?? '#1E88E5', boxShadow: 'none' }} variant="contained"
                                                                            onClick={() => { handleClientsDownload(item.File) }}
                                                                        >
                                                                            Файл конфигурации
                                                                        </Button> */}
                                                                </Box>

                                                            </div>
                                                            :

                                                            [7, 40, 4040].includes(item.ServerTypeId)
                                                                ?
                                                                <OutlineConnection
                                                                    onDeleteOpenClick={onDeleteOpenClick}
                                                                    onChangeProtocClick={onChangeProtocClick}
                                                                    onChangeProtocClick_={onChangeProtocClick_}
                                                                    setLoadingState={props.setLoadingState}
                                                                    balance={props.balance}
                                                                    item={item}
                                                                    data={props.data}
                                                                    tg={props.tg}
                                                                    test={test}
                                                                    limit={limit}
                                                                    onInstructionClick={onInstructionClick}
                                                                    /* setLoadingState={props.setLoadingState} */>

                                                                </OutlineConnection>

                                                                :

                                                                item.ServerTypeId == 41
                                                                    ? <TmConnection
                                                                        item={item}
                                                                        tg={props.tg}
                                                                        data={props.data}
                                                                        onDeleteOpenClick={onTmDeleteOpenClick}
                                                                        onUpdateLimitClick={onUpdateLimitOpenClick}
                                                                        setLoadingState={props.setLoadingState}
                                                                        test={test}
                                                                        limit={limit}
                                                                        onInstructionClick={onInstructionClick}
                                                                    >

                                                                    </TmConnection>
                                                                    : item.ServerTypeId == 42
                                                                        ? <PcConnection
                                                                            item={item}
                                                                            tg={props.tg}
                                                                            data={props.data}
                                                                            onDeleteOpenClick={onTmDeleteOpenClick}
                                                                            setLoadingState={props.setLoadingState}
                                                                            test={test}
                                                                            onUpdateLimitClick={onUpdateLimitOpenClick}
                                                                            limit={limit}
                                                                            onInstructionClick={onInstructionClick}
                                                                        >

                                                                        </PcConnection>
                                                                        : item.Code != "none"
                                                                            ?
                                                                            <WgConnection
                                                                                onDeleteOpenClick={onDeleteOpenClick}
                                                                                onChangeProtocClick={onChangeProtocClick}
                                                                                setLoadingState={props.setLoadingState}
                                                                                balance={props.balance}
                                                                                item={item}
                                                                                data={props.data}
                                                                                tg={props.tg}
                                                                                onInstructionClick={onInstructionClick}
                                                                            ></WgConnection>
                                                                            : <NoneConnection
                                                                                onDeleteOpenClick={onDeleteOpenClick}
                                                                                onChangeProtocClick={onChangeProtocClick}
                                                                                setLoadingState={props.setLoadingState}
                                                                                balance={props.balance}
                                                                                item={item}
                                                                                data={props.data}
                                                                                tg={props.tg}

                                                                            ></NoneConnection>

                                                    }
                                                </AccordionDetails>
                                            </Accordion>
                                            {
                                                props.data.length - 1 == i
                                                    ? <div></div>
                                                    : < Divider style={{ color: '#edeef0' }} />
                                            }

                                        </div>
                                    )
                                })


                        }


                    </Box>
                : <div>{/* Загрузка... */}</div>
            }
        </Box>
    );
}
