import { Avatar, Box, Chip, FormControlLabel, FormGroup, Grid, Paper, Typography } from "@mui/material"
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Lottie from "lottie-react";
import React from "react";
import light_precomp from "../../assets/jsons/light_precomp.json";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { g_language } from "../../core/UrlService";
import { button_color, text_color } from "../../themes/themeTelegram";
export default function ResultPage(props: any) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    React.useEffect(() => {
        props.tg.MainButton.setText(t("main_button.lk")) //  метод для задания текста
        props.tg.MainButton.show() // показать кнопку 
        props.tg.MainButton.enable() // показать кнопку 
        props.tg.MainButton.color = button_color
        props.tg.MainButton.onClick(() => {
            navigate("/main")
        })
    }, [])

    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

    }, [])
    
    function addDays(theDate, days) {
        var d = new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);

        return d.getDate() + "." + (d.getUTCMonth() < 10 ? "0" + d.getUTCMonth() : d.getUTCMonth()) + "." + d.getFullYear()



    }



    const location = useLocation();

    return (
        <Box sx={{ mt: 2 }}>
            <Box >
                <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                    {t("rule_result.title_1")} <b>{/* {location.state.summ}  */}9 WP</b>
                </Typography>
                <Lottie style={{ height: 200 }} animationData={light_precomp} loop={true} />

                <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                    {t("rule_result.title_2")} <b>{addDays(new Date(), 3)}</b>.
                </Typography>

                <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                    {t("rule_result.title_3")}
                </Typography>

                <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                    {t("rule_result.title_4")}
                </Typography>
            </Box>

            {/* 
            <Box sx={{ mt: 2, mb: 2, textAlign: 'center', position: 'fixed', bottom: 5, left: 5, right: 5 }} >
                <Chip label={<Typography variant="caption" sx={{ color: text_color, width: '100%' }} onClick={() => { navigate("/set_up") }}>
                Получить WhyPN на смартфон бесплатно
                </Typography>} />
            </Box>
           */}
        </Box>


    )
}