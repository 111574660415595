import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getUrl } from '../../core/UrlService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Alert } from '@mui/material';
import InputMask from "react-input-mask";
import Countdown from 'react-countdown';
import light_precomp from "../../assets/Duck.json";
import { bg_color, button_color, text_color } from '../../themes/themeTelegram';
import Lottie from 'lottie-react';
function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function RegistrationForm(props: any) {


    const [s, setS] = useSearchParams()



    const loginRef = React.useRef('') //creating a refernce for TextField Component
    const passRef = React.useRef('') //creating a refernce for TextField Component
    const [waiting, setWaithig] = React.useState<boolean>(false);
    const [disable, setDisable] = React.useState<boolean>(false);

    const [login, setLogin] = React.useState("")
    const [password, setPassword] = React.useState("")

    const [isLoading, setIsLoading] = React.useState(false);

    const navigate = useNavigate();


    React.useEffect(() => {
        var r = s.get("user_id");
        if (r != null) {
            setLogin(r)
        }
    }, [])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        setIsLoading(true)
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            login: data.get('login'),
            password: data.get('password'),
        });

        setError("")



        getUrl("/api/login?username=" + login + "&pass=" + password)
            //getUrl("/api/login?username=" + data.get('login') + "&pass=" + data.get('password'))
            .then(res => 
                {
                    debugger
                    if (res.ok){
                        navigate("/")
                    }
                    else {
                        setIsLoading(false)
                        setErrorText("Неверный логин или пароль")
                    }
                }
                    ,error => {
                        setIsLoading(false)
                        setErrorText("Неверный логин или пароль")
                        /* navigate ("/login") */
                        // setErrorText("sds")
                        //setIsLoaded(true);
                        //setError(error);
                    })
            
          
        

    };

    const onRegCLick = () => {

        fetch("https://lk.whypn.com/api/approve_email?phone=" + mobile)
            .then(
                (result) => {
                    setWaithig(true)
                    setIsButtonAvailable(true)
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )

        /* fetch(getUrl("/api/login", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 1297445062)) */
        /*   fetch("http://localhost:5010/api/login?login=")l
            .then(res => res.json())
            .then(
              (result) => {
             
              },
              (error) => {
                //setIsLoaded(true);
                //setError(error);
              }
            ) */
    }


    const onCheckCLick = () => {
        setDisable(true)
        getUrl("/api/check_code?phone=" + mobile + "&code=" + userCode)
            //fetch("http://localhost:5020/api/check_code?phone=" + mobile + "&code=" + userCode)
            .then(res => res.json())
            .then(
                (result) => {



                    if (result.Phone.String == "") {
                        navigate("/after_registration", { state: { mobile: mobile } })
                    }
                    else
                        if (result.Phone.String == "error") {
                            setErrorText("Неверный код")
                        }
                        else if (result.Phone.String == "error_number") {
                            setErrorText("Превышенно число попыток, попробуйте еще раз")
                        }
                        else {
                            navigate("/")
                        }



                    setDisable(false)
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )

        /* fetch(getUrl("/api/login", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 1297445062)) */
        /*   fetch("http://localhost:5010/api/login?login=")
            .then(res => res.json())
            .then(
              (result) => {
             
              },
              (error) => {
                //setIsLoaded(true);
                //setError(error);
              }
            ) */
    }


    var approve_email = () => {


        fetch("http://localhost:5010/api/approve_email?login=").then(res => res.json())
            .then(
                (result) => {
                    /*  setData(result.Connections); */
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }


    const onSignInCLick = () => {
        /* fetch(getUrl("/api/login", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 1297445062)) */
        fetch("http://localhost:5010/api/login?login=")
            .then(res => res.json())
            .then(
                (result) => {
                    /*  setData(result.Connections); */
                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }


    const handleChange1 =
        (prop: keyof any) => (event: React.ChangeEvent<HTMLInputElement>) => {



            var m = event.target.value.replace('+', '').replace(/\s+/g, "").replace('(', '').replace(')', '')
            setMobile(m);

            setIsButtonAvailable(m.indexOf('_') != -1 || m == "")
            /*  if (m.indexOf('_') != -1) {
                 setResponceError("Номер указан неверно")
             } */

        };

    const handleChange2 =
        (prop: keyof any) => (event: React.ChangeEvent<HTMLInputElement>) => {



            var m = event.target.value.replace('+', '').replace(/\s+/g, "").replace('(', '').replace(')', '')
            setUserCode(m);

            //setIsButtonAvailable(m.indexOf('_') != -1 || m == "")
            /*  if (m.indexOf('_') != -1) {
                 setResponceError("Номер указан неверно")
             } */

        };


    const [mobile, setMobile] = React.useState("");
    const [userCode, setUserCode] = React.useState("");

    const [error, setError] = React.useState("");

    const [errorText, setErrorText] = React.useState("");
    const [isButtonAvailable, setIsButtonAvailable] = React.useState<boolean>(true);
    const Completionist = () => <span></span>;

    const renderer = ({ hours, minutes, seconds, completed }: any) => {
        if (completed) {
            // Render a complete state

            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <span>
                    {minutes} мин {seconds} сек
                </span>
            );
        }
    };

    var rere = () => {

        setIsButtonAvailable(false)
    }

    var onHelpClick = () => {
        window.open("https://t.me/whypnbot/start=add_phone", "_blank")
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            {/* <Container component="main" maxWidth="xs"> */}

            {
                isLoading
                    ? <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2, flexDirection: 'column', alignItems: 'center' }}>
                        <Lottie style={{ height: 200 }} animationData={light_precomp} loop={true} />
                        <Typography /* className="pre_" */ sx={{ mt: 1 }} color={text_color} >I'm thinking... </Typography>

                        {/*             <CircularProgress sx={{ display: 'flex', justifyContent: 'center', color: props.tg.themeParams.text_color ?? '#1E88E5' }} />
*/}        </Box>

                    : <Box
                        sx={{
                            background: 'white',
                            display: 'flex',
                            boxShadow: 'none',
                            flexDirection: 'column',
                            /*   justifyContent: 'center',
                              flexWrap: 'wrap', */
                            borderRadius: '12px',
                            listStyle: 'none',

                            m: 0,
                            margin: "0, auto",

                        }}

                    >
                        <Box sx={{ p: 4, background: bg_color, borderRadius: '12px' }} >



                            <Typography component="h1" variant="h5" textAlign={"center"}>
                                Вход
                            </Typography>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }} style={{ width: '100%' }}>
                                {/*          <TextField
                                margin="normal"
                             
                                fullWidth
                                id="login"
                                label="Имя пользователя в Telegram"
                                name="login"
                                autoComplete="login"
                                autoFocus
                            /> */}
                                {/* <InputMask
                            mask="+7 (999) 999 9999"
                            style={{ width: '100%' }}

                            value={mobile}
                            maskChar={"_"}
                            onChange={handleChange1('mobile')}



                        >
                            {() => <TextField
                                label="Номер телефона"
                                required
                                fullWidth

                                margin="normal"
                                type="text"
                                style={{ width: '100%' }}
                                sx={{ mt: 1 }}
                                variant="outlined"
                            />}
                        </InputMask> */}

                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="login"
                                    label="Логин"
                                    name="login"
                                    autoComplete="login"
                                    autoFocus
                                    value={login}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setLogin(event.target.value);
                                    }}
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Пароль"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    value={password}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setPassword(event.target.value);
                                    }}
                                />
                                {/*      <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                                {/*       <Button
              type="submit"
              fullWidth
              variant="contained" */}
                                <Button
                                    /*  className='pay_button'  */
                                    variant="contained"
                                    aria-label="add to shopping cart"
                                    sx={{ mt: 1, color: 'white', borderRadius: 0, backgroundColor: button_color, p: 1, width: '100%' }}
                                    style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                    type="submit"
                                    disabled={password == "" || login == ""}
                                >
                                    Войти
                                </Button>

                                <Box sx={{ textAlign: 'center', mt: 2 }} onClick={() => window.open("https://t.me/whypnbot?start=web_auth", "_blank")}>
                                    <Typography variant='body2' /* className="pre_" */ sx={{ mt: 1 }} color={text_color} > Ещё нет аккаунта WhyPN? </Typography>
                                    <Typography variant='body2' /* className="pre_" */ sx={{ mt: 1, textDecoration: 'underline', color: button_color }} color={text_color} >Зарегистрироваться</Typography>

                                </Box>
                                {/*  <Box sx={{ mt: 1 }}>
                <Typography textAlign={"center"}>или</Typography>
              </Box> */}
                                {/*   <Box sx={{ mt: 1 }}>
                            <Button  variant="contained" aria-label="add to shopping cart" sx={{ color: 'white', borderRadius: 0, backgroundColor: '#2196f3', p: 1, width: '100%' }}
                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                disabled={isButtonAvailable}
                                onClick={onRegCLick}>
                                Получить код
                            </Button>
                        </Box> */}


                                {
                                    waiting
                                        ? <div style={{ paddingTop: 20 }}>

                                            {
                                                isButtonAvailable
                                                    ? <Typography variant="caption" textAlign={"center"}>Повторить попытку можно через <Countdown date={Date.now() + 180000} renderer={renderer} onComplete={() => { return rere() }} /> </Typography>
                                                    : <div></div>
                                            }
                                            <TextField
                                                style={{ width: '100%' }}
                                                label="Код из смс"
                                                /*  variant="standard" */
                                                value={userCode}
                                                onChange={handleChange2('userCode')}

                                                autoComplete='oneTimeCode'
                                                margin="normal"
                                                type="text"
                                            />

                                            <Box sx={{ mt: 1, mb: 1 }}>
                                                <Typography variant="body2" style={{ textAlign: 'center' }}>смс может приходить с задержками, пожалуйста, подождите, оно точно придет</Typography>
                                            </Box>
                                            <Box sx={{ mt: 1 }}>
                                                <Button variant="contained" aria-label="add to shopping cart"
                                                    sx={{ color: 'white', borderRadius: 0, backgroundColor: '#2196f3', p: 1, width: '100%' }}
                                                    style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                                    onClick={onCheckCLick}
                                                    disabled={disable}
                                                >
                                                    Вперед!
                                                </Button>
                                            </Box>

                                        </div>
                                        : <div>
                                            {
                                                error != ""
                                                    ? <Alert severity="error" style={{ marginTop: 10 }}>{error}</Alert>
                                                    : <div></div>
                                            }
                                        </div>
                                }
                                {
                                    errorText == ""
                                        ? <></>
                                        :
                                        <Alert severity="error" sx={{ mt: 2, textAlign: 'center' }}>
                                            <Box sx={{ textAlign: 'center' }}>
                                                <Typography style={{ color: 'red' }} variant="subtitle1">{errorText}</Typography>
                                                <Typography variant="body2">Нужна помощь? Напишите нам: <Typography variant="body2" style={{ color: button_color, textDecoration: "underline" }} onClick={onHelpClick}>@whypnbot</Typography></Typography>
                                            </Box>
                                        </Alert>

                                }

                            </Box>
                        </Box>
                        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
                    </Box>
            }
            {/*        </Container> */}
        </ThemeProvider >
    );
}