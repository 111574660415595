import { createTheme } from "@mui/material";

let tg = (window as any).Telegram.WebApp;
let scheme = tg.colorScheme

export let button_color = "#3390ec"
export let button_text_color = '#ffffff'
export let text_color = "#000000"
export let bg_color = '#ffffff'
export let hint_color = '#707579'
export let secondary_bg_color = tg.themeParams.secondary_bg_color ?? '#f9f9f9'

/* if (scheme == 'dark') {
  button_color = tg.themeParams.button_color ?? "#3390ec"
  button_text_color = tg.themeParams.button_text_color ?? '#ffffff'
  text_color = tg.themeParams.text_color ?? "#000000"
  bg_color = tg.themeParams.bg_color ?? '#ffffff'
  hint_color = tg.themeParams.hint_color ?? '#707579'
  secondary_bg_color = tg.themeParams.secondary_bg_color ?? '#f4f4f5'

}
else { */
  button_color = tg.themeParams.button_color ?? "#3390ec"
  button_text_color = tg.themeParams.button_text_color ?? '#ffffff'
  text_color = tg.themeParams.text_color ?? "#000000"
  bg_color =  "#f9f9f9"//tg.themeParams.secondary_bg_color ?? '#f4f4f5'
  hint_color = tg.themeParams.hint_color ?? '#707579'
  secondary_bg_color = tg.themeParams.bg_color ?? '#ffffff'
/* } */




export const themeTelegram = createTheme({

  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: button_color,
        },
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: button_color,
          color: button_text_color,
          ":hover": {
            backgroundColor: button_color,
            color: button_text_color
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: button_color,
          color: button_text_color,
          ":hover": {
            backgroundColor: button_color,
            color: button_text_color
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: button_color,
          color: button_text_color,
          ":hover": {
            backgroundColor: button_color,
            color: button_text_color
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          backgroundColor: button_color,
          color: button_text_color,
          ":hover": {
            backgroundColor: button_color,
            color: button_text_color,
          },
        }
      }
    },
  },


  palette: {
    text: {
      primary: text_color,
      secondary: hint_color,
    },


    primary: {
      main: bg_color,
      contrastText: text_color,
    },

    secondary: {
      main: hint_color,
    },
  }

})
