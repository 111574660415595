import { Avatar, Box, Card, CircularProgress, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import "./styles.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLocation, useNavigate } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LanguageIcon from '@mui/icons-material/Language';
import Moment from 'moment';
import CelebrationIcon from '@mui/icons-material/Celebration';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import { getUrl } from '../../core/UrlService';
const TotalIncomeCard = () => (
    <Card sx={{ p: 2 }}>
        <List sx={{ py: 0 }}>
            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                <ListItemAvatar>
                    <Skeleton variant="rectangular" width={44} height={44} />
                </ListItemAvatar>
                <ListItemText
                    sx={{ py: 0 }}
                    primary={<Skeleton variant="rectangular" height={20} />}
                    secondary={<Skeleton variant="text" />}
                />
            </ListItem>
        </List>
    </Card>
);


export default function TariffInfo_v2(props: any) {
    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = React.useState([]);

    React.useEffect(() => {

        debugger
        getUrl("/api/tariff_info")
            .then(res => res.json())
            .then(
                (result) => {
                    setData(result);
                },
                (error) => {
                    navigate("/login")
                    //setIsLoaded(true);
                    //setError(error);
                }
            )
    }, [])

    const onMainBlockClick = () => {
        navigate("/tariff_desc", { state: { conn: props.data } })
    }


    var getTariffPrcie = () => {

        var price = 0
        var conn = (props.data as any);
        for (var i = 0; i < conn?.length; i++) {
            price += conn[i].TarrifPerDay.Int64 == 0 ? 3 : 0
        }
        //  props.data == null ? 0 :(props.data as any)?.length * 3}

        return price;
    }


    var getDeviceIcon = (id: any) => {
        switch (id) {
            case 1:
                return <AndroidIcon />
            case 2:
                return <AppleIcon />
            case 3:
                return <LaptopWindowsIcon />
            case 4:
                return <LaptopWindowsIcon />
            default:
                return <LaptopWindowsIcon />
        }
    }

    var getAvatar = (id: any) => {
        if (id == "Android") {
            // return "🤝"
            return (
                <Avatar sx={{ bgcolor: '#69F0AE' }}>
                    <AndroidIcon />
                </Avatar>
            )
        }
        if (id == "IPhone") {
            //return "🌐"
            return (
                <Avatar sx={{ bgcolor: '#2196F3' }}>
                    <AppleIcon />
                </Avatar>
            )
        }
        if (id == "Windows") {
            return (
                <Avatar sx={{ bgcolor: '#FFC107' }}>
                    <LaptopWindowsIcon />
                </Avatar>
            )
        }

        if (id == "MacOS") {
            //return "🗓"
            return (
                <Avatar sx={{ bgcolor: '#D84315' }}>
                    <LaptopWindowsIcon />
                </Avatar>
            )
        }




        //return "⭐️"
        return (
            <Avatar sx={{ bgcolor: '#4527A0' }}>
                <LaptopWindowsIcon />
            </Avatar>
        )
    }

    var onBackClick = () => {
        navigate("/")
    }
 


    var getType = (t) => {

        switch (t) {
            case "monthly": {
                return "₽/месяц"
            }
            case "daily":
                return "₽/день"
            default:
                return "₽/день"
        }
    }

    return (
        <div>
            <Box mb={1} mt={1} sx={{
                /*    background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white', */
                display: 'flex',
                color: props.tg.themeParams.text_color ?? '#ffffff',
                flexDirection: 'row',
                alignItems: 'center',

            }}>
                <IconButton aria-label="delete" sx={{ p: 0, pr: 1 }} style={{  }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography color={props.tg.themeParams.text_color ?? 'black'} variant="h6" gutterBottom sx={{ m: 0 }}> Описание тарифа</Typography>
            </Box>
            {
                props.isLoading
                    ? TotalIncomeCard()

                    : data.length == 0
                        ? <List sx={{ minWidth: '100%', maxWidth: 360}}>
                            {
                                [1, 2].map((x: any) => {
                                    /*     const formatDate = Moment(x.Time).format('DD.MM.YYYY')
                                        var sum = x.TotalNow - x.TotalBefore */

                                    return (
                                        <div style={{ width: '100%' }} >
                                            <ListItem
                                                sx={{ mb: 2 }}
                                                style={{ boxShadow: 'none', borderRadius: '12px', width: '100%', background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white' }}

                                            >
                                                <ListItemAvatar>
                                                    <Skeleton></Skeleton>
                                                </ListItemAvatar>
                                                <ListItemText primary={<Skeleton></Skeleton>}
                                                    secondaryTypographyProps={{ }}
                                                    style={{ }} />

                                            </ListItem>
                                            {/*  <Divider variant="inset" component="li" /> */}
                                        </div>
                                    )
                                })
                            }
                        </List>
                        : <List sx={{ minWidth: '100%', maxWidth: 360, 
                         }}>
                            {
                                data.map((x: any) => {
                                    /*     const formatDate = Moment(x.Time).format('DD.MM.YYYY')
                                        var sum = x.TotalNow - x.TotalBefore */

                                    return (
                                        <div style={{ background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white' ,padding:12, borderRadius: 12,marginBottom: 10 }}>
                                            <Grid container spacing={2} mb={2} style={{ alignItems: 'start' }}>
                                                <Grid item xs={8}>
                                                    <Box sx={{ display: 'flex',}}>
                                                        <Box pr={2}  sx={{}} >
                                                            {getAvatar(x.Device.String)}
                                                        </Box>

                                                        <Box>
                                                            <Box mb={1} sx={{}} ><b>{x.Device.String == "" ? "Мобильное устройство" : x.Device.String} </b></Box>
                                                            <Box mb={1} sx={{ }} > {x.Protocol.String}</Box>
                                                            <Box mb={1} sx={{ }} > {x.Region.String == "" ? "Россия" : x.Region.String} </Box>
                                                            <Box sx={{ }} >{x.Count}</Box>
                                                        </Box>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Typography textAlign={'right'}> {(x.TariffLimit.Int64 == 0 ? 3 : x.TariffPrice.Int64) * x.Count} {getType(x.Type.String)}</Typography>

                                                </Grid>
                                            </Grid>
                                        </div>
                                        /* <ListItem
                                            sx={{ mb: 2 }}
                                            style={{ boxShadow: 'none', borderRadius: '12px', width: '100%', color: props.tg.themeParams.text_color ?? 'black', background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white' }}
                                            secondaryAction={
                                                <div>{(x.TariffLimit.Int64 == 0 ? 3 : x.TariffPrice.Int64) * x.Count} {getType(x.Type.String)}</div>
                                            }
                                        >
                                            <ListItemAvatar>
                                                {getAvatar(x.Device.String)}
                                            </ListItemAvatar>
                                            <ListItemText primary={<div>{x.Device.String == "" ? "Мобильное устройство" : x.Device.String} {x.Protocol.String} - {x.Region.String == "" ? "Россия" : x.Region.String} </div>} secondary={x.Count}
                                                secondaryTypographyProps={{ color: props.tg.themeParams.text_color ?? 'black' }}
                                                style={{ color: props.tg.themeParams.text_color ?? 'black' }} />

                                        </ListItem> */
                                        /*  <Divider variant="inset" component="li" /> */

                                    )
                                })
                            }
                        </List>
            }
        </div>

    );
};
