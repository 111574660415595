import * as React from 'react';
import { Theme, styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { bg_color, text_color } from '../../themes/themeTelegram';
import logo from "../../assets/mango_3.png"
import logo_2 from "../../assets/mango_1.png"
import { Button, Typography } from '@mui/material';
import { Chip } from "@mui/material"
import Carousel from 'react-material-ui-carousel'
import { useNavigate } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import SimpleImageSlider from 'react-simple-image-slider';

import c1 from "../../assets/routers/c1.png"
import c2 from "../../assets/routers/c2.png"
import c3 from "../../assets/routers/c3.png"
import c4 from "../../assets/routers/c4.png"
import c5 from "../../assets/routers/c5.png"

var items = [
  {
    name: "Random Name #1",
    description: "Probably the most random thing you have ever seen!"
  },
  {
    name: "Random Name #2",
    description: "Hello World!"
  }
]

const ProductHeroLayoutRoot = styled('section')(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    /*  height: '80vh', */
    /*  minHeight: 500,
     maxHeight: 1300, */
  },
}));

const images = [
  { url: c1 },
  { url: c2 },
  { url: c3 },
  { url: c4 },
  { url: c5 },
];

const Background = styled('div')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  zIndex: -2,
});

interface ProductHeroLayoutProps {
  sxBackground: SxProps<Theme>;
}

export default function ProductHeroLayout(
  props: any,
) {
  const { sxBackground, children } = props;
  const navigate = useNavigate()

  return (
    <ProductHeroLayoutRoot>
      <Container
        sx={{
          mt: 3,

          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 1000
        }}
      >

        <Box style={{ display: 'flex' }}>
          <Typography color={"#313131"} variant='h4'>GL.iNet x WhyPN </Typography>
          {/* <Typography color={"#313131"} variant='h4'>&nbsp;x WhyPN</Typography> */}
        </Box>

        {/* <Typography color={'#ffdf07'} style={{fontWeight:700}} variant='h5'>Mango</Typography> */}

        <Typography sx={{ mt: 1 }} textAlign={"center"} color={"#313131"} variant='h6'>
          Роутер со встроенным WhyPN
        </Typography>

        <Typography /* sx={{ mt: 1 }} */ textAlign={"center"} color={"#313131"} variant='h6'>
          {/* Новый уровень безопасности */}
          Универсальное решение для всех типов устройств
        </Typography>



        {/* <Chip onClick={()=>{navigate("/router_order")}} sx={{ mt: 2 ,p:1}} style={{ background: '#ffdf07', color:'#313131' }} 
        label={
        <Typography variant="h6" sx={{ color: 'black', width: '100%' }} >
          заказать
        </Typography>
      
      } /> */}
        {/* <img src={logo} width={"100%"} height={"50%"} /> */}



        {children}
        {/*     <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            background: bg_color,
            opacity: 0.5,
            zIndex: -1,
          }}
        /> */}
        <Background sx={sxBackground} />

        <Box >

          <Container /* style={{ width: '100%', display: 'flex', justifyContent: 'center' }} */>
            <Box style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '9px',
              justifyContent: 'center',
              marginTop: 10,

            }}>
              <Chip style={{ background: "#ffdf07" }} label={
                <Typography variant='body2' color={"#5f5b5b"} onClick={() => { props.onItemClick(1) }}>Что в комплекте?</Typography>
              } />
              <Chip style={{ border: "1px solid #ffdf07", background: 'white' }} label={
                <Typography variant='body2' color={"#5f5b5b"} onClick={() => { props.onItemClick(2) }} >✨ Бонусы при предзаказе ✨</Typography>} />
              <Chip style={{ background: "#ffdf07" }} label={
                <Typography variant='body2' color={"#5f5b5b"} onClick={() => { props.onItemClick(3.2) }} >Преимущества</Typography>
              } />
              <Chip style={{ background: "#ffdf07" }} label={
                <Typography variant='body2' color={"#5f5b5b"} onClick={() => { props.onItemClick(4) }} >Требования</Typography>
              } />
              <Chip style={{ background: "#ffdf07" }} label={
                <Typography variant='body2' color={"#5f5b5b"} onClick={() => { props.onItemClick(5) }}>FAQ</Typography>
              } />
            </Box>
          </Container>
          {/*    <Box sx={{ position: 'absolute', mt: 5, zIndex: 1000 }}>
            <Typography variant='h6' color={"#5f5b5b"} onClick={() => { props.onItemClick(0.6) }}>Что в комплекте?</Typography>
            <Typography variant='h6' sx={{ mt: 1 }} color={"#5f5b5b"} onClick={() => { props.onItemClick(1) }} >Дополнительно</Typography>
            <Typography variant='h6' sx={{ mt: 1 }} color={"#5f5b5b"} onClick={() => { props.onItemClick(1.7) }} >Преимущества</Typography>
            <Typography variant='h6' sx={{ mt: 1 }} color={"#5f5b5b"} onClick={() => { props.onItemClick(2.2) }} >Требования</Typography>
            <Typography variant='h6' sx={{ mt: 1 }} color={"#5f5b5b"} onClick={() => { props.onItemClick(3) }}>FAQ</Typography>
          </Box> */}
          <Box style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
            <SimpleImageSlider
              width={"40vh"}
              height={"40vh"}
              images={images}
              showBullets={false}
              showNavs={false}
              autoPlay={true}
              bgColor='none'
              style={{ display: 'flex', justifyContent: 'center' }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography variant='h4' color={"#313131"}>17 990 RUB</Typography>
          </Box>
          {/*  <Button onClick={() => navigate("/router_order")} variant="contained" aria-label="add to shopping cart" sx={{ mt: 1, åborderRadius: 0, p: 1, width: '100%' }}
            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none', background: '#ffdf07', color: '#313131' }} >

            <Typography variant='h6' color={"#313131"}>Оформить предзаказ</Typography>
          </Button >
 */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography variant='h4' color={"#313131"}>SOLD OUT</Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant='body1' fontWeight={"bold"} color={"#313131"}>⚡ +5000 WP в подарок 🎁</Typography>
            <Typography variant='body1' fontWeight={"bold"} color={"#313131"}>Эксклюзивная партия: 10 устройств</Typography>
          </Box>
        </Box>


        {/*  <Box
          component="img"
          src="/static/themes/onepirate/productHeroArrowDown.png"
          height="16"
          width="12"
          alt="arrow down"
          sx={{ position: 'absolute', bottom: 32 }}
        /> */}
      </Container>
    </ProductHeroLayoutRoot>
  );
}
