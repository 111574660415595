import { Avatar, Backdrop, Box, Chip, FormControlLabel, FormGroup, Grid, Paper, Typography } from "@mui/material"
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Lottie from "lottie-react";
import React from "react";
import test_precomp from "../../assets/test.json";
import phone from "../../assets/Comp 1.json";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { getUrl, current_user, g_language } from "../../core/UrlService";
import { useTranslation } from "react-i18next";
import PayListtFirst from "../../components/Balance/PayListtFirst";
import { bg_color, text_color } from "../../themes/themeTelegram";


export default function SetUpPage(props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    React.useEffect(() => {
        props.tg.MainButton.setText(t("main_button.top_up")) //  метод для задания текста
        props.tg.MainButton.show() // показать кнопку 
        /* props.tg.MainButton.disable() // показать кнопку  */
       
        props.tg.MainButton.onClick(() => {
            navigate("/pay_first")
        })


        /* if (!open){
            props.tg.MainButton.disable()
        } */
    }, [])


    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

    }, [])

    const [open, setOpen] = React.useState(false);
    const [isErrror, setIsError] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        
        props.tg.MainButton.disable()
        props.tg.MainButton.color = "#d3d3d3"
        setOpen(true); 


    
       /*  setIsError(true)
        setOpen(false); */


         fetch(getUrl("/api/create_by_country_bonus", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
             //fetch("http://localhost:5010" + "/api/sub?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
             .then(res => {
                 
                    setTimeout(() => {
                        navigate("/result", { state: { summ: 9 } })
                      }, 3000);
 
             },
                 (error) => {
                     setOpen(false);
                
                     props.tg.showPopup({
                        title: t("set_up.error_title"), 
                        message: t("set_up.error_desc"),
                        buttons: [
                            {
                                id:1,
                                type:"default",
                                text:t("set_up.error_close_button")
                            }
                        ]
                    }, (button_id:any)=>{
                        props.tg.MainButton.enable()
                       
                        setOpen(false); 
                    })
            
                
            
                   
                 }) 

    };
    return (
        <>
            {
                open ?
                    <motion.div

                        style={{ height: "100vh", display: 'flex', alignItems: 'center', justifyContent:'center' }}
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                    >

                        <Box sx={{ mt: 2 }}>


                            <Box >
                                {/* <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                                    <b>Пожалуйста, подождите</b>
                                </Typography> */}
                                <Lottie style={{ height: 200 }} animationData={test_precomp} loop={true} />

                                <Typography align="center" variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                                    <b>{t("set_up.title_1")}</b>
                                </Typography>

                                <Typography align="center" variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                                    {t("set_up.loading")}
                                </Typography>

                            </Box>

                        </Box>

                    </motion.div>
                    :
                    <Box sx={{ mt: 2 }}>

                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}
                            onClick={handleClose}
                        >
                            <div>test</div>
                        </Backdrop>

                        <Box >


                            {/*  <PayListtFirst  tg={props.tg}></PayListtFirst> */}
                            <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                                <b>{t("set_up.title_2")}</b>
                            </Typography>
                            <Lottie style={{ height: 200 }} animationData={phone} loop={true} />

                            <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                                <b>{t("set_up.title_3")}:</b>
                            </Typography>

                            {/*   <ul>
                                <li> <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                                    IPhone (IOS 17 и выше)
                                </Typography></li>
                                <li> <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                                    Android-смартфон (Android 11 и выше)
                                </Typography></li>

                            </ul> */}

                            <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                                - {t("set_up.title_4_1")}
                            </Typography>
                            <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                                - {t("set_up.title_4_2")}
                            </Typography>


                        </Box>
                        <Box sx={{ mt: 2, mb: 2, textAlign: 'center', position: 'fixed', bottom: 5, left: 5, right: 5 }} >
                            <Chip style={{ background: bg_color}} label={<Typography variant="caption" sx={{ color: text_color, width: '100%' }}  onClick={handleOpen}>
                                {t("set_up.chip")}
                            </Typography>} />
                        </Box>

                    </Box>
            }

        </>


    )
}