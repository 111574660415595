import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid, IconButton, LinearProgress, LinearProgressProps, Skeleton, Typography } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { g_language } from '../../../core/UrlService';
function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" style={{ background: 'rgb(239, 239, 239)' }}  {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" /* color="text.secondary" */>{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


export default function WgConnection(props: any) {
    const { t, i18n } = useTranslation()
    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    },[])

    var a = Moment(props.item.Date)
    const formatDate = Moment(props.item.Date).utcOffset('GMT-00:00').format('DD.MM.YYYY HH:mm')


    const navigate = useNavigate();

    const [isOpen, setIsOpen] = React.useState(false);

    const [state, setState] = React.useState<any>({
        value: '',
        copied: false,
    })
    const [progress, setProgress] = React.useState(70);

    return (

        <div>

            <Grid container spacing={2} mb={1.5}>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="start"
                        alignItems="center"


                    >


                        <IconButton
                             
                            size="small"

                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                            sx={{ mr: 1, textTransform: 'none',borderRadius: 0}}
                            onClick={() => { navigate("/edit", { state: { id: props.item.Id, name: props.item.Name?.String == "" ? "empty" : props.item.Name?.String } }) }}
                        >
                            <EditIcon />
                        </IconButton >


                        <IconButton
                             
                            size="small"

                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                            sx={{ mr: 1, textTransform: 'none', borderRadius: 0, backgroundColor: '#ffc107' }}
                            onClick={() => { props.setLoadingState(); props.onChangeProtocClick(props.item.Id, props.item.OnMulticonnection, 
                                props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, [7, 40, 41].includes(props.item.ServerTypeId), props.item.TariffId.Int64) }}
                        >
                            <RefreshIcon />
                        </IconButton >

                        {(props.data.length > 1) ?
                            <IconButton
                                 
                                size="small"

                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                sx={{ textTransform: 'none', borderRadius: 0, }}
                                onClick={() => { props.setLoadingState(); props.onDeleteOpenClick(props.item.Id, props.item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, [7, 40, 41].includes(props.item.ServerTypeId)) }}
                            >
                                <DeleteIcon />
                            </IconButton>
                            : <div></div>
                        }

                    </Grid>


                </Grid>

            </Grid>


            <Typography>{t("unrichable")}</Typography>
        </div>

    );
}