import { Avatar, Box, Chip, FormControlLabel, FormGroup, Grid, Paper, Typography } from "@mui/material"
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { g_language } from "../../core/UrlService";
import { bg_color, text_color } from "../../themes/themeTelegram";
export default function RulePage_4(props: any) {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    React.useEffect(() => {
        props.tg.MainButton.setText(t("main_button.next"))  //  метод для задания текста
       
        props.tg.MainButton.show() // показать кнопку 
        props.tg.MainButton.onClick(() => {
            navigate("/rule_page_5")
        })
    }, [])

    var onHelpClick = () => {
        props.tg.openLink("https://telegram.org/blog/telegram-stars")
    }

    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

    }, [])
    return (
        <Box sx={{ mt: 2 }}>
            <Box >

                <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                    <b> {t("rule_page_4.main_title")} </b>
                </Typography>
                <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                {t("rule_page_4.title_1_1")} <b>WP Coin</b>,  {t("rule_page_4.title_1_2")}
                </Typography>
                <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                {t("rule_page_4.title_2")}:
                </Typography>

                {/*  <ul>
                    <li> <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                        1 WP Coin = 1 Российский рубль
                    </Typography></li>
                    <li> <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                        1 WP Coin = 1 Telegram Star
                    </Typography></li>
                    <li> <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                        1 WP Coin = 0.02 USDT
                    </Typography></li>
                </ul>
 */}


                <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                    {t("rule_page_4.title_3_1")}
                </Typography>
                <Typography variant="body1" sx={{ color: text_color, width: '100%' }}>
                    {t("rule_page_4.title_3_2")}
                </Typography>

                <Typography variant="body1" sx={{ color: text_color, width: '100%'}}>
                     {t("rule_page_4.title_3_3")}
                </Typography>


                <Typography variant="body1" sx={{ color: text_color, width: '100%', mt: 2 }}>
                    <i> {t("rule_page_4.title_4_1")} <a onClick={onHelpClick}><u>{t("rule_page_4.title_4_2")}</u></a></i>
                </Typography>

            </Box>

            <Box sx={{ mt: 2, mb: 2, textAlign: 'center', position: 'fixed', bottom: 5, left: 5, right: 5 }} >
            <Chip style={{ background: bg_color}} label={<Typography variant="caption" sx={{ color: text_color, width: '100%' }}  onClick={() => { navigate("/set_up") }}>
                {t("rule_page_4.chip")}
                </Typography>} />
            </Box>
        </Box>


    )
}