import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CodeConainer from '../../components/Code/CodeContainer';
import CurrentBalance from '../../components/Balance/CurrentBalance';
import PayList from '../../components/Balance/PayList';
import Settings from '../../components/Settings/Settings';
import { useNavigate } from 'react-router';
import Subscription from '../../components/New/Subscription';
import NewUserPay from '../../components/New/NewUserPay';
import HelpIcon from '@mui/icons-material/Help';
import * as Flags from 'country-flag-icons/react/3x2'
import { current_user, g_language, getUrl } from '../../core/UrlService';
import Tariff_v2 from '../../components/Tariff/Tariff_v2';
import RefreshIcon from '@mui/icons-material/Refresh';
import MainAd from '../../components/Advertising/MainAd';
import NewYearAd from '../../components/Advertising/NewYearAd';
import TmAd from '../../components/Advertising/TmAd';
import "./style.css"
import { useTranslation } from 'react-i18next';
import NewUserMain from '../pages_v2/NewUserMain';
import Lottie from 'lottie-react';
import light_precomp from "../../assets/Duck.json";
import { text_color } from '../../themes/themeTelegram';
import { useDispatch, useSelector } from 'react-redux';
import { incrementAsync, incrementByAmount, selectCount, setUserInfo } from '../../redux/counterSlice';
export default function Main(props: any) {

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const [data, setData] = React.useState(null);
    const [conn, setConn] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(true);
    const [isConnLoading, setIsConnLoading] = React.useState(false);
    const [isSub, setIsSub] = React.useState(true);
    const [ip, setIp] = React.useState(null);

    const [chached, setChached] = React.useState(false);
    const [lastRedisKey, setLastRedisKey] = React.useState("");
    const [newStatus, setNewStatus] = React.useState(-1);

    var get_flag = () => {
        if (ip != null && (ip as any).Code != "") {

            var Flag = Flags[(ip as any).Code];
            return <Flag title={(ip as any).Code} style={{ height: 15 }} />
        }

    }



    var repeatCreateConn = () => {
        setIsLoading(true)
        getUrl("/api/repeat_by_country" + "key=" + lastRedisKey)
            //fetch("http://localhost:5010/api/ip")
            .then(res => {

                window.location.reload();

            },
            error=> navigate("/login")
            );

    }


    var onHelpClick = () => {
        navigate("/help")
    }


    const fetchData = async () => {
        const response = await fetch("/api/data");
        const json = await response.json();
        setData(json);
    };
    fetchData();
    const count = useSelector(selectCount);

    const dispatch = useDispatch();

    React.useEffect(() => {

        props.tg.MainButton.hide() // скрыть кнопку


        const fetchData = async () => {
            const response = await fetch("/api/data");
            const json = await response.json();
            setData(json);
        };
        fetchData();


        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

        setIsLoading(true)
        if (props.tg?.initDataUnsafe?.start_param == undefined) {
            getUrl("/api/connections")
                //fetch("http://localhost:5010" + "/api/connections?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                .then(res => {
                
                    return res.json()
                }
                )
                .then(
                    (result) => {

                        var a = result
                        debugger
                       // setConn(result);

                        if (result != null && (result as any).IsEmpty == true) {
                            navigate("/greetings")
                        }
                        else {

                            getUrl("/api/user_info")
                                .then(res => res.json())
                                .then(
                                    (result_) => {
                                        debugger
                                        setTimeout(() => {
                                            debugger
                                            /*  navigate("/result", { state: { summ: 9 } }) */
                                            dispatch(incrementByAmount(a))
                                            dispatch(setUserInfo(result_))
                                            navigate("/main", { state: { /* conn: result, *//*  data: result_  */ } })



                                            /*  navigate("/main", { state: { conn: result, data: result_ } }) */
                                        }, 3000);
                                    },
                                    (error) => {
                                        navigate("/login")
                                    }
                                )


                            /*  fetch(getUrl("/api/sub", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
                                 //fetch("http://localhost:5010" + "/api/sub?id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :    current_user       ))
                                 .then(res => res.json())
                                 .then(
                                     (result) => {
                                         setIsSub(result)
                                       
                                     },
                                     (error) => {
                                         //setIsLoaded(true);
                                         //setError(error);
                                     }
                                 ) */

                        }

                    },
                    (error) => {
                        debugger
                        navigate("/login")
                    }
                )
        }
    }, [])



    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2, flexDirection: 'column', alignItems: 'center' }}>
            <Lottie style={{ height: 200 }} animationData={light_precomp} loop={true} />
            <Typography /* className="pre_" */ sx={{ mt: 1 }} color={text_color} >I'm thinking... </Typography>

            {/*             <CircularProgress sx={{ display: 'flex', justifyContent: 'center', color: props.tg.themeParams.text_color ?? '#1E88E5' }} />
 */}        </Box>
    );
}
