import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Button, Box } from '@mui/material'
import PcAd from './PcAd';
import NewYearAd from './NewYearAd';
import TmAd from './TmAd';
import NewsAd from './NewsAd';


 export  default function MainAd(props:any)
{
    var items = [
     /*    <NewYearCompetitionAd tg={props.tg}   info={props.info}  conn={props.conn}></NewYearCompetitionAd>, */
        <PcAd tg={props.tg}  info={props.info}  conn={props.conn}></PcAd>,
        <NewsAd tg={props.tg}  info={props.info}  conn={props.conn}></NewsAd>
  /*       <RouterAd tg={props.tg}  info={props.info}  conn={props.conn}></RouterAd> */
        /* <TmAd tg={props.tg}   info={props.info}  conn={props.conn}></TmAd> */
        
    ]

    return (
        <Carousel  className={"rrr_"}>
            {
                items.map( (item, i) => <Box>{item}</Box> )
            }
        </Carousel>
    )
}

