import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid, IconButton, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function Support(props: any) {

    const [data, setData] = React.useState([]);
    const navigate = useNavigate();
    const params = useParams();

    var onCreateCodeClick = () => {
        /*    fetch("http://localhost:5010" + '/api/update?id=' + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   1297445062        ) + '&name=' + "test")
               .then(res =>
                   navigate("history")
               ); */
        window.open('https://donate.qiwi.com/payin/WhyPN', '_blank')
    }

    var onBackClick = () => {
        navigate("/")
    }

    return (
        <Box /* style={{ background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white' }} */>
            <Box mb={2} mt={1} sx={{
                /*    background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white', */
                display: 'flex',
                color: props.tg.themeParams.text_color ?? '#ffffff',
                flexDirection: 'row',
                alignItems: 'center',

            }}>
                <IconButton aria-label="delete" sx={{ p: 0, pr: 1 }} style={{ color: props.tg.themeParams.text_color ?? '##000000' }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography color={'black'} variant="h6" gutterBottom sx={{ m: 0 }}> Поддержать проект</Typography>
            </Box>
            <Box style={{ background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white', borderRadius: '12px', boxShadow: 'none', color: props.tg.themeParams.text_color ?? '##000000' }} sx={{ p: 2 }}>
                Мы активно развиваемся, создаем новые возможности для удобного и безопасного подключения к недоступным интернет-ресурсам, добавляем новые функции и постоянно адаптируемся к новым реалиям, чтобы наш сервис <b>WhyPN</b> сохранял стабильную работу для всех пользователей


            </Box>




            <Box mt={2}>
                <Button style={{
                    width: '100%',
                    background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : 'white',
                    color: 'black',
                    boxShadow: 'none',
                    borderRadius: '12px'
                }} variant="contained" onClick={onCreateCodeClick}>Поддержать WhyPN</Button>
            </Box>
            <Box mt={2}>
                <Typography color={props.tg.themeParams.text_color ?? '##000000'}  style={{ width: '100%', fontSize: '12px' }} variant="body2">Средства с пожертвований не учитываются в балансе пользователя, а идут напрямую на поддержку проекта WhyPN. По всем вопросам о донатах обращайтесь в поддержку бота @whypnbot
                </Typography>
            </Box>
        </Box >
    );
} 