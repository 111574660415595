import { Avatar, Box, Card, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import "./styles.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import { g_language, getUrl } from '../../core/UrlService';

import ComputerIcon from '@mui/icons-material/Computer';
import { useTranslation } from 'react-i18next';

export default function PcAd(props: any) {
    const navigate = useNavigate();
    const [data, setData] = React.useState([]);
    const {t, i18n} = useTranslation ()

    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

    }, [])

    const onMainBlockClick = () => {
        navigate("/tariff")
    }


    var getTariffPrcie = () => {

        var price = 0
        var conn = (props.data as any);
        for (var i = 0; i < conn?.length; i++) {
            price += conn[i].TarrifPerDay.Int64 == 0 ? 3 : 0
        }
        //  props.data == null ? 0 :(props.data as any)?.length * 3}

        return price;
    }

    return (
        <div>
            <Box
                onClick={() => {
                      
                    navigate("/new", { state: { info: props.info, conn: props.conn } } ) }}
                className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root css-jvzmdc  css-1y3mnrk" sx={{ p: 2, borderRadius: '12px', height: '80%' }}>
                {/*  <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root css-jvzmdc"> */}
                <div className="MuiCardContent-root css-18mhetb">
                    <div className="css-1iakqhe">
                        <div className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-6flbmm"/*  focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="DescriptionTwoToneIcon" */>
                            {/*    <path d="M13 4H6v16h12V9h-5V4zm3 14H8v-2h8v2zm0-6v2H8v-2h8z" opacity=".3"></path><path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z">
                            </path> */}

                            <ComputerIcon sx={{ fontSize: 80, opacity: 0.3 }}></ComputerIcon>
                        </div>
                    </div>
                    <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-1 MuiGrid-direction-xs-column css-mgf9hi">
                        <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 css-h719to">
                            {/*  <div className=' MuiTypography-root MuiTypography-h3 MuiTypography-alignCenter css-aa5ry5'><h2>Подключи свой ПК к WhyPN</h2></div> */}

                            <div style={{ display: 'flex', textAlign: 'center', flexDirection: 'column' }}>
                                <Typography variant="h6">{t("adv.pc_title")}</Typography>
                                <Typography style={{  }} variant="subtitle2">{t("adv.pc_desc")}</Typography>

                            </div>


                            {/*  <h3 className="MuiTypography-root MuiTypography-h3 MuiTypography-alignCenter css-aa5ry5">Подключи свой ПК к WhyPN</h3> */}
                        </div>
                        {/* <div className="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 css-h719to">
                            <p className="MuiTypography-root MuiTypography-body1 MuiTypography-alignCenter css-1sgcksc">Daily page view</p>
                        </div> */}
                    </div>
                </div>
                {/*  </div> */}
            </Box>


        </div>
    );
};
