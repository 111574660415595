import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getUrl } from '../../core/UrlService';
import { useNavigate } from 'react-router-dom';
import { Alert, Tab, Tabs } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import RegistrationForm from './RegistrationForm';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function Login() {

  const loginRef = React.useRef('') //creating a refernce for TextField Component
  const passRef = React.useRef('') //creating a refernce for TextField Component
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      login: data.get('login'),
      password: data.get('password'),
    });

    getUrl("/api/login?username=" + data.get('login') + "&pass=" + data.get('password'))
      .then(res => navigate("/"))
      .then(
        (result) => {

          navigate("/")
          /*   localStorage.setItem("token") */
          /*  setData(result.Connections); */
        },
        (error) => {
          debugger/* navigate ("/login") */
          setErrorText("sds")
          //setIsLoaded(true);
          //setError(error);
        }
      )

  };

  const onRegCLick = () => {
    debugger
    navigate("/registration")
    /* fetch(getUrl("/api/login", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 1297445062)) */
  /*   fetch("http://localhost:5010/api/login?login=")
      .then(res => res.json())
      .then(
        (result) => {
       
        },
        (error) => {
          //setIsLoaded(true);
          //setError(error);
        }
      ) */
  }

  const onSignInCLick = () => {
    /* fetch(getUrl("/api/login", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 1297445062)) */
    fetch("http://localhost:5010/api/login?login=")
      .then(res => res.json())
      .then(
        (result) => {
          /*  setData(result.Connections); */
        },
        (error) => {
          //setIsLoaded(true);
          //setError(error);
        }
      )
  }
  const [errorText, setErrorText] = React.useState("");
  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (

    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">

      <Box
          sx={{
            background: 'white',
            display: 'flex',
            boxShadow: 'none',
            flexDirection: 'column',
            /*   justifyContent: 'center',
              flexWrap: 'wrap', */
            borderRadius: '12px',
            listStyle: 'none',
            mt:2
        
          }}
        >
   

        <RegistrationForm></RegistrationForm>
     {/*  <TabContext value={value}> */}
        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}  aria-label="disabled tabs example">
            <Tab style={{width:'50%'}} label="Логин/пароль" value="1" />
            <Tab style={{width:'50%'}}label="телефон" value="2" />
           
          </TabList>
        </Box> */}
       {/*  <TabPanel value="1">
        <CssBaseline />

          <Box
            sx={{
            
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >

            <Typography component="h1" variant="h5">
              Вход
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="login"
                label="Логин"
                name="login"
                autoComplete="login"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Пароль"
                type="password"
                id="password"
                autoComplete="current-password"
              /> */}
              {/*      <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
              {/*       <Button
              type="submit"
              fullWidth
              variant="contained" */}
           {/*    <Button className='pay_button' variant="contained" aria-label="add to shopping cart" sx={{mt:1, color: 'white', borderRadius: 0, backgroundColor: '#ffc107', p: 1, width: '100%' }}
                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} type="submit">
                Войти
              </Button> */}
          {/*     <Box sx={{ mt: 1 }}>
                <Typography textAlign={"center"}>или</Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Button className='pay_button' variant="contained" aria-label="add to shopping cart" sx={{ color: 'white', borderRadius: 0, backgroundColor: '#2196f3', p: 1, width: '100%' }}
                  style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onRegCLick}>
                  Зарегестрироваться
                </Button>
              </Box>
 */}

           {/*    {
                errorText == ""
                  ? <></>
                  : <Alert sx={{ mt: 2 }} severity="error">Неверный логин или пароль</Alert>
              } */}
              {/*     <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          {/*   </Box>
          </Box> */}
          {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
       
   {/*      </TabPanel> */}
        {/* <TabPanel value="2"><RegistrationForm></RegistrationForm></TabPanel>
        
      </TabContext> */}
    </Box>
    

      </Container>
    </ThemeProvider>

  );
}