import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Avatar, Box, Grid, IconButton, LinearProgress, LinearProgressProps, Link, Skeleton, styled, Typography, withStyles } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import LaptopWindowsIcon from '@mui/icons-material/LaptopWindows';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';
import { g_language } from '../../../core/UrlService';
import { bg_color, secondary_bg_color, text_color } from '../../../themes/themeTelegram';


function LinearProgressWithLabel(props: LinearProgressProps & { value: number, val: number, t: any }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, pb: 2 }}>

            {/* <Grid container spacing={2} mb={1.5}> */}
            {/*  <Grid item xs={11}> */}
            <Box sx={{ width: '100%' }}>
                <LinearProgress className='linear-progress-pc' variant="determinate" style={{ background: 'rgb(239, 239, 239)', color: 'red', display: 'flex', alignItems: 'center' }} value={100 * props.val / props.value} />
            </Box>
            <Box sx={{ width: '100%' }}>

                {
                    props.val == -1
                        ? <Typography style={{ textAlign: 'center' }}>{props.t("loading")}...</Typography>
                        : <Typography style={{ textAlign: 'center' }}>{`${props.val.toFixed(2)} из ${props.value} Гб`}</Typography>
                }
                {/*  </Grid> */}
                {/*  <Grid item xs={1}> */}

            </Box>

            {/*   </Grid> */}

            {/*  </Grid> */}
        </Box>
        /*       <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '90%', mr: 1 }}>
                      <LinearProgress variant="determinate" style={{ background: 'rgb(239, 239, 239)' }}  {...props} />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                      <Typography variant="body2" />{`${Math.round(
                          props.val,
                      )} из 80`}</Typography>
                  </Box>
              </Box> */


    );
}


export default function OutlineConnection(props: any) {

    const BootstrapButton = styled(Button)({
        root: {
            '&:hover': {
                backgroundColor: secondary_bg_color,
                color: "red",
            },
        }
    });


    var getTariffPrcie = (conn: any) => {

        var price = 0


        price = conn.TariffPrice.Int64 == 0 ? 3 : conn.TariffPrice.Int64

        //  props.data == null ? 0 :(props.data as any)?.length * 3}

        return price;
    }

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = React.useState(false);

    const [state, setState] = React.useState<any>({
        value: '',
        copied: false,
    })
    const [progress, setProgress] = React.useState(70);


    var onCopyClick = (event: any) => {
        setState({ value: 'dddsd', copied: false })
        setAnchorEl(event.currentTarget);
        setIsOpen(true)


    }

    const [open1, setOpen1] = React.useState(false);

    const [currentConn, setCurrentConn] = React.useState(-2);
    /*   var onChangeProtocClick = (id: any, isMulti: any, user_id: any, isOutline: boolean) => {
  
           
          if (props.balance > 0) {
              setCurrentConn(id)
              setOpen1(true)
          }
          else {
              navigate("/pay_refresh")
          }
      } */

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const { t, i18n } = useTranslation()
    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])

    var getAvatar = (id: any) => {
        if (id == "Android") {
            // return "🤝"
            return (
                <div style={{ marginRight: 5 }}>
                    <AndroidIcon />
                </div>
            )
        }
        if (id == "IPhone") {
            //return "🌐"
            return (
                <div style={{ marginRight: 5 }}>
                    <AppleIcon /></div>

            )
        }
        if (id == "Windows") {
            return (
                <div style={{ marginRight: 5 }}>
                    <LaptopWindowsIcon /></div>
            )
        }

        if (id == "MacOS") {
            //return "🗓"
            return (
                <div style={{ marginRight: 5 }}>
                    <LaptopWindowsIcon /></div>
            )
        }

        return <div style={{ marginRight: 5 }}><AndroidIcon /></div>
    }


    return (

        <div>

            <Grid container spacing={0} mb={1}>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="start"
                        alignItems="center"


                    >


                        <IconButton

                            size="small"

                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                            sx={{ mr: 1, textTransform: 'none', borderRadius: 0, }}
                            onClick={() => { navigate("/edit", { state: { id: props.item.Id, name: props.item.Name?.String == "" ? "empty" : props.item.Name?.String } }) }}
                        >
                            <EditIcon />
                        </IconButton >


                        <IconButton

                            size="small"

                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                            sx={{ mr: 1, textTransform: 'none', borderRadius: 0 }}
                            onClick={() => {
                                props.onChangeProtocClick(props.item.Id, props.item.OnMulticonnection,
                                    props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, [7, 40, 41].includes(props.item.ServerTypeId), props.item.TariffId.Int64)
                            }}
                        >
                            <RefreshIcon />
                        </IconButton >

                        {(props.data.length > 1) ?
                            <IconButton

                                size="small"

                                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }}
                                sx={{ textTransform: 'none', borderRadius: 0 }}
                                onClick={() => { props.setLoadingState(); props.onDeleteOpenClick(props.item.Id, props.item.OnMulticonnection, props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : 671643425, [7, 40, 41].includes(props.item.ServerTypeId)) }}
                            >
                                <DeleteIcon />
                            </IconButton>
                            : <div></div>
                        }

                    </Grid>


                </Grid>

            </Grid>

            {props.item.Code != '-3' && props.item.InternalId.String?.toString() != ""
                ?




                <div>

                    {/* <Grid container spacing={2} mb={1.5}>
                                                                            <Grid item xs={5}>
                                                                                <Typography>ID</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={7}>
                                                                                <Typography textAlign={'right'}>{ props.item.Id}</Typography>
                                                                            </Grid>
                                                                        </Grid>

                                                                        <Grid container spacing={2} mb={1.5}>
                                                                            <Grid item xs={5}>
                                                                                <Typography>Дата создания</Typography>
                                                                            </Grid>


                                                                            <Grid item xs={7}>

                                                                                <Typography textAlign={'right'}>{formatDate} UTC</Typography>
                                                                            </Grid>
                                                                        </Grid> */}


                    <Grid container spacing={2} mb={1.5}>
                        <Grid item xs={5}>
                            <Typography>{t("tariff")}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography textAlign={'right'}>{getTariffPrcie(props.item)} WP/{t("day")}</Typography>

                        </Grid>
                    </Grid>


                    <Grid container spacing={2} mb={1.5}>
                        <Grid item xs={5}>
                            <Typography>{t("region")}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            {
                                props.item.Flag?.String == ""
                                    ? <Typography textAlign={'right'}> Регион вычислен автоматически</Typography>
                                    : <Typography textAlign={'right'}><i className={"em em-flag-" + props.item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> {t("cities." + props.item.Flag?.String?.toString())}</Typography>
                            }

                        </Grid>
                    </Grid>



                    {/*        <Grid container spacing={2} mb={1.5}>
                                                                        <Grid item xs={5}>
                                                                            <Typography>{t("region")}</Typography>
                                                                        </Grid>
                                                                        <Grid item xs={7}>
                                                                            <Typography textAlign={'right'}><i className={"em em-flag-" +  props.item.Flag?.String?.toString().toLowerCase()} aria-role="presentation" aria-label="Netherlands Flag"></i> { props.item.City?.String}</Typography>
                                                                        </Grid>
                                                                    </Grid> */}

                    <Grid container spacing={2} mb={1.5}>
                        <Grid item xs={5}>
                            <Typography>{t("server")}</Typography>
                        </Grid>
                        <Grid item xs={7} >
                            <Typography textAlign={'right'}>{props.item.Domain}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} mb={1.5}>
                        <Grid item xs={5}>
                            <Typography>{t("status")}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <Typography textAlign={'right'}>{props.item.IsEnabled ? "🟢 OK" : "🔴 " + t("disable")}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} mb={1.5}>
                        <Grid item xs={5}>
                            <Typography>{t("device")}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                            <div style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "flex-end",
                                flexDirection: "row"
                            }}>
                                {/*   {getAvatar(props.item.Device.String)} */}
                                {
                                    props.item.Device.String != null
                                        ? <Typography style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }} textAlign={'right'}>{props.item.Device.String}</Typography>
/*                                         ? <Typography style={{color: props.tg.themeParams.text_color ?? '#1E88E5'}} textAlign={'right'}><Link style={{color: props.tg.themeParams.text_color ?? '#1E88E5'}}  href={"https://whypn.com/instructions/" + props.item.Device.String}>{props.item.Device.String}</Link> </Typography>
 */                                        : <Typography textAlign={'right'}>Не выбран</Typography>
                                }
                                {/*     <HelpIcon style={{ height: 10 }} /> */}
                            </div>

                        </Grid>
                    </Grid>

                    {/*    <Grid item xs={12}> */}

                    {/* </Grid> */}
                    {/*   <Grid item xs={7}>
                                                                        <Typography textAlign={'right'}>{ props.item.IsEnabled ? "🟢 OK" : "🔴 Заблокировано"}</Typography>
                                                                    </Grid> */}




                    {props.item.TariffLimit.Int64 > 0
                        ? <Grid item xs={12}>

                            <Grid item xs={5}>

                                <div>
                                    <Typography>{t("use_traffic")}</Typography>
                                    <Typography variant="caption">{t("use_traffic_last_days")}</Typography>
                                </div>


                            </Grid>
                            <Grid item xs={7}>
                                <LinearProgressWithLabel value={props.item.TariffLimit.Int64} val={props.limit} t={t} />
                            </Grid>


                        </Grid>
                        : <></>}

                    <Box
                        display="flex"
                        flexDirection={"column"}
                        /*  justifyContent="center"
                         alignItems="center" */
                        sx={{
                            borderRadius: '12px'
                        }}

                    >
                        <Box sx={{ mb: 1, textAlign: 'center' }}>
                            <Typography style={{ color: props.tg.themeParams.text_color ?? '#1E88E5', textAlign: 'center', wordWrap: 'break-word' }}>
                                {t("conn_key")}
                            </Typography>
                        </Box>
                        <CopyToClipboard text={props.item.Code}
                            onCopy={() => {
                                setState({ copied: true })
                                    ;
                            }}
                        >

                            <Box /* onClick={() => props.test(props.item.Id)} */ bgcolor={props.tg != undefined && props.tg.colorScheme != 'light' ? props.tg.themeParams.bg_color : '#EFEFEF'} style={{
                                backgroundColor: secondary_bg_color, borderRadius: '12px', display: 'flex',
                                justifyContent: 'space-between', alignItems: 'center'
                            }} p={2} className={props.tg != undefined && props.tg.colorScheme != 'light' ? '' : '_link_box'}>


                                <Grid container onClick={onCopyClick} style={{
                                    background: secondary_bg_color
                                }} >
                                    <Grid item xs={10} style={{
                                        background: secondary_bg_color
                                    }}>

                                        {/* <Typography onClick={()=>test( props.item.Code)}>fdfd</Typography> */}


                                        <Typography style={{ color: props.tg.themeParams.text_color ?? '#1E88E5', textAlign: 'left', wordWrap: 'break-word' }}>
                                            {props.item.Code}
                                        </Typography>



                                    </Grid>
                                    <Grid item xs={2} textAlign={"right"} style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }}>
                                        <OpenInNewIcon></OpenInNewIcon>
                                    </Grid>
                                </Grid>
                                {/* 
            
 */}
                            </Box>
                        </CopyToClipboard>

                        {/*                <Box>


                            <Box style={{ color: '#5e35b1' }}>
                                <Typography style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }} textAlign={'center'} variant="subtitle2">{t("on_and_off")} WhyPN <b>{t("only")}</b> {t("through_the_app")} Outline</Typography>

                            </Box>

                            <Box sx={{ mt: 1 }} style={{ textAlign: 'center' }}>
                                <Typography style={{ color: props.tg.themeParams.text_color ?? '#1E88E5', textAlign: 'center' }} variant="caption" >
                                    {t("not_through")}
                                </Typography>
                            </Box>
                        </Box> */}

                        {
                            !isOpen
                                ? <div></div>
                                : <Typography sx={{ textAlign: 'center', color: 'lightgreen' /*'dark' ? 'rgb(255 255 255 / 8%)' : 'white'  */ }}>{t("copy")}</Typography>
                        }

                        {/*  <div>{ props.item.Code} </div> */}
                        {/*  <Typography >{ props.item.Code}</Typography> */}
                    </Box>



                    <Box mt={2}>
       
                        <BootstrapButton disableRipple variant="contained" aria-label="add to shopping cart" /* disabled={setEnabled()} */ sx={{ borderRadius: 0, p: 1, width: '100%', background: secondary_bg_color, color: text_color }}
                            style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={() => props.onInstructionClick(props.item.Instruction.String)}>
                            {t("instruction")}
                            {/*  <CreditCardIcon sx={{ ml: 0.5 }} /> */}
                        </BootstrapButton >

                    </Box>


                </div>
                : <Typography>{t("unrichable")}</Typography>
            }
        </div>

    );
}