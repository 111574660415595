import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { AppBar, Button, CircularProgress, Grid, IconButton, Link, Skeleton, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router';

import HelpIcon from '@mui/icons-material/Help';
import * as Flags from 'country-flag-icons/react/3x2'
import RefreshIcon from '@mui/icons-material/Refresh';

import "./style.css"
import { useTranslation } from 'react-i18next';
import NewUserMain from '../pages_v2/NewUserMain';
import Lottie from 'lottie-react';
import light_precomp from "../../assets/RepairDuck.json";
import { text_color } from '../../themes/themeTelegram';
import { useDispatch, useSelector } from 'react-redux';
import { incrementAsync, incrementByAmount, selectCount, setUserInfo } from '../../redux/counterSlice';
import { g_language, getUrl } from '../../core/UrlService';
export default function Main(props: any) {

    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const [data, setData] = React.useState(null);
    const [conn, setConn] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(true);
    const [isConnLoading, setIsConnLoading] = React.useState(false);
    const [isSub, setIsSub] = React.useState(true);
    const [ip, setIp] = React.useState(null);

    const [chached, setChached] = React.useState(false);
    const [lastRedisKey, setLastRedisKey] = React.useState("");
    const [newStatus, setNewStatus] = React.useState(-1);

    var get_flag = () => {
        if (ip != null && (ip as any).Code != "") {

            var Flag = Flags[(ip as any).Code];
            return <Flag title={(ip as any).Code} style={{ height: 15 }} />
        }

    }



    var repeatCreateConn = () => {
        setIsLoading(true)
        fetch(getUrl("/api/repeat_by_country", props.tg) + "key=" + lastRedisKey)
            //fetch("http://localhost:5010/api/ip")
            .then(res => {

                window.location.reload();

            }
            );

    }


    var onHelpClick = () => {
        navigate("/help")
    }


    const fetchData = async () => {
        const response = await fetch("/api/data");
        const json = await response.json();
        setData(json);
    };
    fetchData();
    const count = useSelector(selectCount);

    const dispatch = useDispatch();

    React.useEffect(() => {

        props.tg.MainButton.hide() // скрыть кнопку


        const fetchData = async () => {
            const response = await fetch("/api/data");
            const json = await response.json();
            setData(json);
        };
        fetchData();


        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);


        

                
            
    }, [])



    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2, flexDirection: 'column', alignItems: 'center' }}>
            <Lottie style={{ height: 200 }} animationData={light_precomp} loop={true} />
            <Typography  sx={{ mt: 1 }} color={text_color} > {t("repair")} </Typography>

            {/*             <CircularProgress sx={{ display: 'flex', justifyContent: 'center', color: props.tg.themeParams.text_color ?? '#1E88E5' }} />
 */}        </Box>
    );
}
