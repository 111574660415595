import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Link, Radio, RadioGroup, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { current_user, g_language, getUrl } from '../../core/UrlService';
import { useTranslation } from 'react-i18next';
import { bg_color, text_color } from '../../themes/themeTelegram';
import { useDispatch } from 'react-redux';
import { setCurrent } from '../../redux/counterSlice';

export default function FeedbackResult(props: any) {

    const { t, i18n } = useTranslation()
    React.useEffect(() => {
      i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])
    
    const [data, setData] = React.useState([]);
    const navigate = useNavigate();
    const params = useParams();

    var onCreateCodeClick = () => {
        /*    fetch("http://localhost:5010" + '/api/update?id=' + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ) + '&name=' + "test")
               .then(res =>
                   navigate("history")
               ); */
        window.open('https://donate.qiwi.com/payin/WhyPN', '_blank')
    }

    var onBackClick = () => {
        navigate("/")
    }
    const [value, setValue] = React.useState('Yes');
    const [text, setText] = React.useState("");
    const [desc, seDesc] = React.useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const handleText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);

    };

    const dispatch = useDispatch();

    const location = useLocation();

    const onFeedbackClick = () => {
        dispatch(setCurrent(0))
        navigate("/main")

    }

    return (
        <Box /* style={{ background:  bg_color }} */>
            <Box mb={2} mt={1} sx={{
                /*    background:  bg_color, */
                display: 'flex',
                color: text_color,
                flexDirection: 'row',
                alignItems: 'center',

            }}>
             {/*    <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }} onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton> */}
                <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}>{t("leave_comment")}</Typography>
            </Box>


            <Box mt={2} style={{ textAlign: "center", background:  bg_color, borderRadius: '12px', boxShadow: 'none', color: text_color }} sx={{ p: 2 }}>
                <Box>
                    <Typography color={text_color} style={{ width: '100%' }} variant="h6">
                    {t("result_feedback.result_feedback_title")}
                    </Typography>

                </Box>

                {/*      {location.state.desc == 200
                    ?

                    <Box>
                        <Typography color={text_color} style={{ width: '100%' }} variant="h6">
                            Спасибо за ваш отзыв!
                        </Typography>
                        
                    </Box>
                    :
                    <Box>
                        <Typography color={text_color} style={{ width: '100%' }} variant="h6">
                            Спасибо за ваш отзыв!
                        </Typography>
                        <Typography color={text_color} style={{ width: '100%' }} variant="body2">
                            Вам начислен бонус 9 рублей
                        </Typography>
                    </Box>
                } */}

            </Box>


            <Box mt={2}>
                {/*   <Button style={{
                    width: '100%',
                    background:  bg_color,
                    color: text_color,
                    boxShadow: 'none',
                    borderRadius: '12px'
                }} variant="contained" onClick={onCreateCodeClick}>Отправить</Button> */}

                <Button   variant="contained" aria-label="add to shopping cart" sx={{ borderRadius: 0,  p: 1, width: '100%' }}
                    style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onFeedbackClick} disabled={desc == "d"}>
                    {t("result_feedback.result_feedback_return_btn")}
                    {/*    <CreditCardIcon sx={{ ml: 0.5 }} /> */}
                </Button >


            </Box>
            {/*       <Box mt={2}>
                <Typography color={text_color}  style={{ width: '100%', fontSize: '12px' }} variant="body2">Средства с пожертвований не учитываются в балансе пользователя, а идут напрямую на поддержку проекта WhyPN. По всем вопросам о донатах обращайтесь в поддержку бота @whypnbot
                </Typography>
            </Box> */}
        </Box >
    );
} 