import { Avatar, Box, Card, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemText, Skeleton, ThemeProvider, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import "./styles.css"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { g_language } from '../../core/UrlService';
import { button_color, themeTelegram } from '../../themes/themeTelegram';




export default function CurrentBalance(props: any) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const clickOnBalanceBox = () => {
        navigate("/what_is_wp")
    }

    const prepareTotalBalance = (balance: any) => {
        if (balance > 9999) {
            balance = Math.trunc(balance / 1000)
            balance = balance + "k"
        }
        return balance
    }



    useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])
    return (
        <ThemeProvider theme={themeTelegram}>


            <style
                dangerouslySetInnerHTML={{
                    __html: `
      .test_2:after {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(140.9deg, `+ button_color + ` -14.02%, rgba(144, 202, 249, 0) 70.5%);
    border-radius: 50%;
    top: -160px;
    right: -130px;
}

.test_2::before {
    content: "";
    position: absolute;
    width: 210px;
    height: 210px;
    background: linear-gradient(210.04deg, `+ button_color + ` -50.94%, rgba(144, 202, 249, 0) 83.49%);
    border-radius: 50%;
    top: -30px;
    right: -180px;
    cursor: pointer;
}

.test_2 {
   
    color: rgb(54, 65, 82);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    background-image: none;
    border-radius: 12px;
    border: none rgba(144, 202, 249, 0.145);
    overflow: hidden;
    position: relative;
}
    `
                }}
            ></style>
            <Box

                sx={{ p: 2, background: themeTelegram.palette.primary.main,/* background: props.tg != undefined && props.tg.colorScheme == 'dark' ? 'rgb(255 255 255 / 8%)' : '#F9F9F9',  */borderRadius: '12px' }}
                className='test_2'
                onClick={clickOnBalanceBox}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Typography variant="caption" sx={{ color: themeTelegram.palette.text.secondary,/*  color: '#BBBBBB' */ fontSize: ' 0.675rem', textTransform: 'uppercase' }} >
                        {t("balance")}
                    </Typography>
                </Box>
                <List sx={{ py: 0 }}>
                    <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                        {props.data != null
                            ?

                            <Skeleton
                                variant="rounded"
                                sx={{
                                    width: 55, height: 55,

                                    mr: 2
                                }}
                            >

                            </Skeleton>


                            : <ListItemAvatar>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        width: 55, height: 55,
                                        /*  backgroundColor: 'rgb(255, 248, 225);',
                                         color: 'rgb(255, 193, 7)', */
                                        mr: 2
                                    }}
                                >
                                    <CreditCardIcon sx={{ width: 35, height: 35, }} />
                                </Avatar>
                            </ListItemAvatar>
                        }

                        {props.data != null
                            ? <ListItemText
                                sx={{
                                    /*  py: 0,
                                     mt: 0.45,
                                     mb: 0.45 */
                                }}
                                primary={
                                    <Typography variant="h5" sx={{ color: themeTelegram.palette.text.primary/* color: props.tg.themeParams.text_color ?? 'black' */ }}>
                                        <Skeleton />
                                    </Typography>
                                }
                                secondary={
                                    /*       <Typography variant="subtitle2" sx={{ color: 'primary.light', mt: 0.25 }}>
                                              История баланса
                                          </Typography> */
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <Typography variant="subtitle2" sx={{ color: themeTelegram.palette.text.primary,/*  color: props.tg.themeParams.text_color ?? 'black', */ width: '100%' }} >
                                            <Skeleton />
                                        </Typography>

                                    </Box>
                                }
                            />
                            : <ListItemText
                                sx={{
                                    /*  py: 0,
                                     mt: 0.45,
                                     mb: 0.45 */
                                }}
                                primary={
                                    <Typography variant="h5" sx={{ color: themeTelegram.palette.text.primary/* color: props.tg.themeParams.text_color ?? 'black' */ }}>
                                        {props.d != null && props.d.length > 0 ? prepareTotalBalance(props.d[0].TotalNow) : 0} WP
                                    </Typography>
                                }
                                secondary={
                                    /*       <Typography variant="subtitle2" sx={{ color: 'primary.light', mt: 0.25 }}>
                                              История баланса
                                          </Typography> */
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="caption" sx={{ color: themeTelegram.palette.text.primary,/*  color: props.tg.themeParams.text_color ?? 'black', */ whiteSpace: 'nowrap' }} >
                                            {t("what_is_wp.what_is_wp_title")}
                                        </Typography>

                                        {/*     <IconButton
                                        style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }}
                                        sx={{ fontSize: 12, alignItems: 'center', p: 0 }}
                                    >
                                        <ArrowForwardIosIcon style={{ color: props.tg.themeParams.text_color ?? '#1E88E5'}} fontSize="inherit" />
                                    </IconButton> */}
                                    </Box>
                                }
                            />
                        }
                    </ListItem>
                </List>
            </Box >
        </ThemeProvider>
        /*    <Box
               onClick={clickOnBalanceBox}
               className='test'
               sx={{ p: 2 }}
               style={{
                   background:  bg_color,
               }}
           >

               <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                   <Typography variant="subtitle2" sx={{ color: props.tg.themeParams.text_color ?? 'black' }} >
                       Баланс
                   </Typography>

               </Box>

               <List sx={{ py: 0 }}>
                   <ListItem alignItems="center" disableGutters sx={{ py: 0, textAlign:'center' }}>
                       <ListItemAvatar>
                           <Avatar
                               variant="rounded"
                               sx={{
                                   width: 55, height: 55,
                                   backgroundColor: 'rgb(255, 248, 225);',
                                   color: 'rgb(255, 193, 7)',
                                   mr: 2
                               }}
                           >
                               <CreditCardIcon sx={{ fontSize: 30 }} fontSize="inherit" />
                           </Avatar>
                       </ListItemAvatar>
                       {props.data.length == 0
                           ? <CircularProgress color="secondary" />
                           : <ListItemText

                               sx={{
                                   py: 0,
                                   mt: 0.45,
                                   mb: 0.45,
                   
                               }}
                               primary={
                                   <Typography variant="h5" sx={{ color: props.tg.themeParams.text_color ?? 'black' }} className=''>
                                       {props.data[0].TotalNow} WP
                                   </Typography>
                               }
                               secondary={
                                   <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                       <Typography variant="subtitle2" sx={{ color: props.tg.themeParams.text_color ?? 'black' }} >
                                           Баланс
                                       </Typography>

                                       <IconButton
                                           style={{ color: props.tg.themeParams.text_color ?? '#1E88E5' }}
                                           sx={{ fontSize: 12, alignItems: 'center', p: 0 }}
                                       >
                                           <ArrowForwardIosIcon fontSize="inherit" />
                                       </IconButton>
                                   </Box>
                               }
                           />
                       }
                   </ListItem>
               </List>

           </Box>
*/



    );
};
