import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Grid, IconButton, Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { current_user, g_language, getUrl } from '../../core/UrlService';
import { useTranslation } from 'react-i18next';
import { bg_color, text_color } from '../../themes/themeTelegram';

export default function WhatIsWP(props: any) {
    const { t, i18n } = useTranslation();
    const [data, setData] = React.useState([]);
    const navigate = useNavigate();
    const params = useParams();

    var onCreateCodeClick = () => {
        fetch(getUrl("/api/update", props.tg) + "id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + '&name=' + "test")
            //fetch("http://localhost:5010" + '/api/update?id=' + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ) + '&name=' + "test")
            .then(res =>
                navigate("history")
            );
    }

    var onBackClick = () => {
        navigate("/")
    }


    React.useEffect(() => {
        props.tg.BackButton.show()
        props.tg.BackButton.onClick(() => {
            navigate("/main")
        })

        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);
    }, [])
    

    var onHelpClick = () => {
        props.tg.openTelegramLink("https://t.me/+ooTBYfw-xI05ZjUy")
    }

    return (
        <Box mb={2}/* style={{ background:  bg_color }} */>
            <Box mb={2} mt={1} sx={{
                /*    background:  bg_color, */
                display: 'flex',
                color: text_color,
                flexDirection: 'row',
                alignItems: 'center',

            }}>
             {/*    <IconButton aria-label="delete" sx={{ p: 0, mr: 1 }}  onClick={onBackClick}>
                    <ArrowBackIcon />
                </IconButton> */}
                <Typography color={text_color} variant="h6" gutterBottom sx={{ m: 0 }}>{t("what_is_wp.what_is_wp_title")}</Typography>
            </Box>
            <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none', color: text_color }} sx={{ p: 2 }}>


                <Box mb={3} style={{color:text_color}}>
                    {t("what_is_wp.def")}
                </Box>

                <Box mb={3}>
                    <Box style={{color:text_color}}><b>{t("what_is_wp.why_title")}</b></Box>

                    <Box style={{color:text_color}}>{t("what_is_wp.why_def_1")}</Box>
                    <Box style={{color:text_color}}>{t("what_is_wp.why_def_2")}</Box>
                    <Box style={{color:text_color}}>{t("what_is_wp.why_def_3")}</Box>
                    <Box style={{color:text_color}}>{t("what_is_wp.why_def_4")}</Box>
                </Box>

                <Box mb={3} style={{color:text_color}}>
                    <Box style={{color:text_color}}><b>{t("what_is_wp.how_title")}</b></Box>
                    {t("what_is_wp.how_def")}
                </Box>

                <Box mb={3} style={{color:text_color}}>
                    <Box style={{color:text_color}}><b>{t("what_is_wp.how_translate_title")}</b></Box>
                    <Box style={{color:text_color}}>{t("what_is_wp.how_translate_def_1")}</Box>
                    <Box style={{color:text_color}}>{t("what_is_wp.how_translate_def_2")}</Box>
                    <Box style={{color:text_color}}>{t("what_is_wp.how_translate_def_3")}</Box>
                </Box>
                <Box mb={3} style={{color:text_color}}>
                    <Box style={{color:text_color}}><b>{t("what_is_wp.is_safe_title")}</b></Box>
                    {t("what_is_wp.is_safe_def")}
                </Box>


                <Box mb={3}>
                    <Box style={{color:text_color}}><b>{t("what_is_wp.more_info_title")}</b></Box>
                    <Box style={{color:text_color}}>{t("what_is_wp.more_info_def_1")}</Box>
                    <Box mt={2}>{t("what_is_wp.more_info_def_2")}</Box>
                    <Box mt={2} style={{color:text_color}}>
                        <Box style={{ display: "inline" }}>
                            {t("what_is_wp.more_info_def_3_1")} <Typography  style={{ display: "inline", textDecoration:"underline", fontWeight:700 }} onClick={onHelpClick}>{t("what_is_wp.blog")}</Typography>. {t("what_is_wp.more_info_def_3_2")}</Box>
                    </Box>
                </Box>
            </Box>
            {/* <Box mt={2}>
                <Button style={{
                    width: '100%',
                    background:  bg_color,
                    color: props.tg.themeParams.text_color ?? '#1E88E5',
                    boxShadow: 'none',
                    borderRadius: '12px'
                }} variant="contained" onClick={onCreateCodeClick}>Поддержать WhyPN</Button>
            </Box>
            <Box mt={2}>
                <Typography style={{ width: '100%', fontSize: '12px' }} variant="body2">Средства с пожертвований не учитываются в балансе пользователя, а идут напрямую на поддержку проекта WhyPN. По всем вопросам о донатах обращайтесь в поддержку бота @whypnbot
                </Typography>
            </Box> */}
        </Box >
    );
}