import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, CircularProgress, Grid, IconButton, Stack } from '@mui/material';
import TelegramIcon from '@mui/icons-material/Telegram';
import { current_user, g_language, getUrl } from '../../core/UrlService';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import StarIcon from '@mui/icons-material/Star';
import { bg_color, text_color } from '../../themes/themeTelegram';

export default function PayDetailPage(props: any) {

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = React.useState(false);


    const { t, i18n } = useTranslation()

    React.useEffect(() => {
        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

    }, [])

    React.useEffect(() => {

        props.tg.MainButton.setText(t("main_button.top_up")) //  метод для задания текста
        props.tg.MainButton.hide() // показать кнопку 
        /* props.tg.MainButton.disable() // показать кнопку  */
       
        props.tg.MainButton.onClick(() => {
          /*   navigate("/pay_detail",  { state: { amount: a }}) */
            
        })


        /*       if (props.tg.initDataUnsafe.user.language_code == "en"
              ) {
                  onStartClick()
              } */

        i18n.changeLanguage(props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.language_code : g_language);

    }, [])

    var b = location.state?.text

    var onWalletClick = () => {

        var a = location.state.amount

        setLoading(true)
        fetch(getUrl("/api/create_order", props.tg) + "amount=" + a + "&currencyCode=" + "RUB" + "&chatid=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user))
            //fetch("http://localhost:5010" + "/api/available")
            .then(res => res.json())
            .then(
                (result) => {


                    props.tg.openTelegramLink(result.data.directPayLink)
                    //window.open(result.data.directPayLink, "_blank")
                    props.tg.close()

                },
                (error) => {
                    //setIsLoaded(true);
                    //setError(error);
                })

        // window.open("https://pay.whyrl.ru/pay?user_id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + "&amount=" + a + "&key=5&status=1", "_blank")
    }

    var onCreateCodeClick = () => {
        /*    fetch("http://localhost:5010" + '/api/update?id=' + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id :   current_user         ) + '&name=' + "test")
               .then(res =>
                   navigate("history")
               ); */
        window.open('https://donate.qiwi.com/payin/WhyPN', '_blank')
    }

    var onBackClick = () => {
        navigate("/")
    }
    /* 
        var onPayClick = () => {
            var a = location.state.instruction
            window.open("https://pay.whyrl.ru/pay?user_id=" + (props.tg.initDataUnsafe.user != undefined ? props.tg.initDataUnsafe.user.id : current_user) + "&amount=" + a + "&key=5&status=1", "_blank")
        } */

    var onStartClick = () => {
        var a = location.state.amount
        fetch(getUrl("/api/payment", props.tg) + "price=" + a)
            .then(res => res.json())
            .then(
                (result) => {
                    var r = JSON.parse(result)

                     
                    props.tg.openInvoice(r.result, (t) => {
                         
                    })
                    props.tg.close()

                },
                (error) => {

                })
    }

    var onPayClick = () => {
        var a = location.state.amount
        getUrl("/api/current_user").then(res => res.json())
        .then(
            (result) => {
                window.open("https://pay.whyrl.ru/pay?user_id=" + result.id + "&amount=" + a + "&key=5&status=1", "_blank")
                if (props.tg != null)
                    props.tg.close()
            },
            (error) => {
                navigate("/login")
                //setIsLoaded(true);
                //setError(error);
            }
        )        
    }
    return (
        <>
            {
                props.tg?.initDataUnsafe?.user?.language_code == "en"
                    ?
                    <Box /* style={{ background:  bg_color }} */>


                        <Typography variant="body1" sx={{ color: text_color, width: '100%', mt:2 }}>
                            <b> {t("first_to_top_up.title_1")}</b>
                        </Typography>
                        <Box mt={2}>




                            <Box sx={{ p: 2, background: 'rgb(255, 248, 225)' }} mt={2} className='card-payment_block' onClick={onStartClick}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                width: 55, height: 55,
                                                backgroundColor: 'white',
                                                color: 'rgb(255, 193, 7)',
                                                mr: 2
                                            }}
                                        >
                                            <StarIcon sx={{ width: 35, height: 35, }} />
                                        </Avatar>

                                        {/*  <CurrencyRubleIcon></CurrencyRubleIcon> */}
                                    </Box>
                                    <Box>
                                        <Stack>


                                            <Typography variant="h6">Telegram Stars</Typography>
                                        </Stack>

                                        <Stack>
                                            <Typography color={"#BBBBBB"} variant="caption">International cards: USD, EUR, AUD, CNY, etc.</Typography>
                                        </Stack>



                                        {/*  <Typography color={'rgb(33, 150, 243)'} fontWeight={500} variant="caption">Еще немного и будет доступно!</Typography> */}
                                    </Box>

                                </Box>

                            </Box>

                            {/*   <Box sx={{ mt: 2, }} className="tt" >
                <Accordion style={{ background: 'rgb(255, 248, 225)' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{ color: '#364152' }}
                        className="tt"
                    >
                        <Typography style={{ color: '#364152' }} variant="h6"> Stars is ...</Typography>


                    </AccordionSummary>
                    <Box sx={{ pr: 2, pl: 2, pb: 2 }}>
                        <Typography style={{ color: '#364152' }} > {t("pay_detail.how_desc")}</Typography>
                        <Box sx={{ mt: 1 }}>
                          
                        </Box>

                    </Box>
                </Accordion>


            </Box> */}




                            {/* {props.tg.initDataUnsafe.user.id == 234051811 ? */}

                            {/* : <></>
            } */}





                        </Box>



                    </Box>


                    :
                    <Box /* style={{ background:  bg_color }} */>
                        <Typography variant="body1" sx={{ color: text_color, width: '100%', mt:2 }}>
                            <b>{t("first_to_top_up.title_1")}</b>
                        </Typography>




                        <Box mt={2}>

                            <Box style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none', color: text_color}} sx={{ p: 2 }}>
                                <Typography variant="body1" sx={{ mb: 1 }} >
                                    {t("pay_detail.subtitle")}
                                </Typography>
                                {
                                    b != null
                                        ? <Typography sx={{ mb: 2 }}>{location.state.text}</Typography>
                                        : <></>
                                }
                                <Box sx={{ p: 2, background: '#e4f9e8', borderRadius: '12px' }} className='card-payment-rubles' onClick={onPayClick}>

                                    <Box sx={{ display: 'flex' }}>
                                        <Box>
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    width: 55, height: 55,
                                                    backgroundColor: 'white',
                                                    color: '#00c852',
                                                    mr: 2
                                                }}
                                            >
                                                <CurrencyRubleIcon sx={{ width: 35, height: 35, }} />
                                            </Avatar>
                                            {/*  <CurrencyRubleIcon></CurrencyRubleIcon> */}
                                        </Box>
                                        <Box>
                                            <Typography variant="h6">{t("pay_detail.rub_title")}</Typography>
                                            <Typography color={"#BBBBBB"} variant="caption">{t("pay_detail.rub_decs")}</Typography>
                                        </Box>
                                    </Box>
                                    {/*   <Button   variant="contained" aria-label="add to shopping cart" sx={{ mt: 2, color: 'white', borderRadius: 0, backgroundColor: 'rgb(25, 118, 210)', p: 1, width: '100%' }}
                    style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onWalletClick}>
                    Рублями
                    <TelegramIcon sx={{ ml: 0.5 }} />
                </Button > */}

                                </Box>


                                <Box sx={{ p: 2, background: 'rgb(255, 248, 225)' }} mt={2} className='card-payment_block' onClick={onStartClick}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box>
                                            <Avatar
                                                variant="rounded"
                                                sx={{
                                                    width: 55, height: 55,
                                                    backgroundColor: 'white',
                                                    color: 'rgb(255, 193, 7)',
                                                    mr: 2
                                                }}
                                            >
                                                <StarIcon sx={{ width: 35, height: 35, }} />
                                            </Avatar>

                                            {/*  <CurrencyRubleIcon></CurrencyRubleIcon> */}
                                        </Box>
                                        <Box>
                                            <Stack>


                                                <Typography variant="h6">Telegram Stars</Typography>
                                            </Stack>

                                            <Stack>
                                                <Typography color={"#BBBBBB"} variant="caption">International cards: USD, EUR, AUD, CNY, etc.</Typography>
                                            </Stack>



                                            {/*  <Typography color={'rgb(33, 150, 243)'} fontWeight={500} variant="caption">Еще немного и будет доступно!</Typography> */}
                                        </Box>

                                    </Box>

                                </Box>

                                {/*   <Box sx={{ mt: 2, }} className="tt" >
                <Accordion style={{ background: 'rgb(255, 248, 225)' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{ color: '#364152' }}
                        className="tt"
                    >
                        <Typography style={{ color: '#364152' }} variant="h6"> Stars is ...</Typography>


                    </AccordionSummary>
                    <Box sx={{ pr: 2, pl: 2, pb: 2 }}>
                        <Typography style={{ color: '#364152' }} > {t("pay_detail.how_desc")}</Typography>
                        <Box sx={{ mt: 1 }}>
                          
                        </Box>

                    </Box>
                </Accordion>


            </Box> */}
                               {/*  {loading
                                    ?
                                    <Box sx={{ p: 2, background: 'rgb(227, 242, 253)' }} mt={2} className='telegram-payment_block' >


                                        <Box sx={{ display: 'flex' }}>
                                            <Box>
                                                <Avatar
                                                    variant="rounded"
                                                    sx={{
                                                        width: 55, height: 55,
                                                        backgroundColor: 'white',
                                                        color: 'rgb(33, 150, 243)',
                                                        mr: 2
                                                    }}
                                                >
                                                    <CircularProgress sx={{ color: 'rgb(33, 150, 243)', width: 35, height: 35, }} />

                                                </Avatar>
                                            </Box>
                                            <Box>
                                                <Stack>


                                                    <Typography variant="h6">{t("pay_detail.crypto_title")}</Typography>
                                                </Stack>

                                                <Stack>
                                                    <Typography color={"#BBBBBB"} variant="caption"> {t("pay_detail.crypto_desc_1")}</Typography>
                                                    <Typography color={"#BBBBBB"} variant="caption">  {t("pay_detail.crypto_desc_2")}</Typography>
                                                </Stack>

                                            </Box>

                                        </Box>
                                    </Box>
                                    :

                                    <Box sx={{ p: 2, background: 'rgb(227, 242, 253)' }} mt={2} className='telegram-payment_block' onClick={onWalletClick}>
                                        <Box sx={{ display: 'flex' }}>
                                            <Box>
                                                <Avatar
                                                    variant="rounded"
                                                    sx={{
                                                        width: 55, height: 55,
                                                        backgroundColor: 'white',
                                                        color: 'rgb(33, 150, 243)',
                                                        mr: 2
                                                    }}
                                                >
                                                    <TelegramIcon sx={{ width: 35, height: 35, }} />
                                                </Avatar>
                                            </Box>
                                            <Box>
                                                <Stack>


                                                    <Typography variant="h6">{t("pay_detail.crypto_title")}</Typography>
                                                </Stack>

                                                <Stack>
                                                    <Typography color={"#BBBBBB"} variant="caption">  {t("pay_detail.crypto_desc_1")}</Typography>
                                                    <Typography color={"#BBBBBB"} variant="caption">  {t("pay_detail.crypto_desc_2")}</Typography>
                                                </Stack>



                                            </Box>

                                        </Box>

                                    </Box>


                                } */}

                                {/* {props.tg.initDataUnsafe.user.id == 234051811 ? */}

                                {/* : <></>
            } */}
                           {/*      <Box sx={{ mt: 2, }} className="tt" >
                                    <Accordion style={{ background: 'rgb(227, 242, 253)' }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                            style={{ color: '#364152' }}
                                            className="tt"
                                        >
                                            <Typography style={{ color: '#364152' }} variant="body1">  {t("pay_detail.how_title")}</Typography>


                                        </AccordionSummary>
                                        <Box sx={{ pr: 2, pl: 2, pb: 2 }}>
                                            <Typography style={{ color: '#364152' }} > {t("pay_detail.how_desc")}</Typography>
                                            <Box sx={{ mt: 1 }}>
                                                <iframe
                                                    style={{ border: 'none', borderRadius: '12px' }}
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    width="100%"
                                                    height="500"
                                                    src="https://www.youtube.com/embed/r_fZ5epCQko?si=KpOxymYLG9O2wdUY"

                                                >
                                                </iframe>
                                            </Box>

                                        </Box>
                                    </Accordion>


                                </Box> */}
                                {/*   <Button   variant="contained" aria-label="add to shopping cart" sx={{ mt: 2, color: 'white', borderRadius: 0, backgroundColor: 'rgb(25, 118, 210)', p: 1, width: '100%' }}
style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onWalletClick}>
Рублями
<TelegramIcon sx={{ ml: 0.5 }} />
</Button > */}



                                {/*      </Box>
*/}

                                {/* <Box mt={2} style={{ background:  bg_color, borderRadius: '12px', boxShadow: 'none', color: text_color}} sx={{ p: 2 }}> */}
                                {/*  <Button   variant="contained" aria-label="add to shopping cart" sx={{ mt: 2, color: 'white', borderRadius: 0, backgroundColor: 'rgb(25, 118, 210)', p: 1, width: '100%' }}
                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onWalletClick}>
                Рублями
                <TelegramIcon sx={{ ml: 0.5 }} />
            </Button > */}

                            </Box>


                            {/*       <Stack >
            <Button   variant="contained" aria-label="add to shopping cart" sx={{ mt: 2, color: 'white', borderRadius: 0, backgroundColor: 'rgb(25, 118, 210)', p: 1, width: '100%' }}
                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onWalletClick}>
                Рублями
                <TelegramIcon sx={{ ml: 0.5 }} />
            </Button >
            <Button   variant="contained" aria-label="add to shopping cart" sx={{ mt: 2, color: 'white', borderRadius: 0, backgroundColor: 'rgb(25, 118, 210)', p: 1, width: '100%' }}
                style={{ borderRadius: '10px', textTransform: 'none', boxShadow: 'none' }} onClick={onWalletClick}>
                Криптовалютой
                <TelegramIcon sx={{ ml: 0.5 }} />
            </Button >

        </Stack> */}
                        </Box>

                    </Box >
            }
        </>




    );
}